<template>
	<v-text-field
		:value="value"
		@input="onInput"
		:rules="rules"
		:label="label"
		:clearable="clearable"
		prepend-icon="mdi-email"
		validate-on-blur
		:counter="maxlen"
		:tabindex="tabindex"
		autocomplete="off"
		type="email"
		:readonly="readonly"
		:disabled="readonly"
		:hint="hint"
		:persistent-hint="persistentHint"
	/>
</template>
<script>
import isEmail from 'validator/lib/isEmail';

export default {
	name: 'EmailInput',
	model: {
		prop: `value`
	},
	props: {
		value: {
			type: String,
			required: false,
			default: ''
		},
		label: {
			type: String,
			required: false,
			default: 'Email'
		},
		tabindex: {
			type: Number,
			required: false,
			default: 0
		},
		clearable: {
			type: Boolean,
			required: false,
			default: true
		},
		readonly: {
			type: Boolean,
			required: false,
			default: false
		},
		disableValidation: {
			type: Boolean,
			required: false,
			default: false
		},
		hint: {
			type: String,
			required: false,
			default: ''
		},
		persistentHint: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => ({
		maxlen: 96
	}),
	computed: {
		rules() {
			return [
				(v) => this.disableValidation || !!v || this.$t('errors.required', { key: this.label }),
				(v) => {
					const minlen = 6;
					return (
						this.disableValidation ||
						(v && v.length >= minlen) ||
						this.$t('errors.minlen', { key: this.label, minlen })
					);
				},
				(v) =>
					this.disableValidation ||
					(v && v.length <= this.maxlen) ||
					this.$t('errors.maxlen', { key: this.label, maxlen: this.maxlen }),
				(v) => (v && isEmail(v)) || this.$t('errors.valid', { key: this.label })
			];
		}
	},
	methods: {
		async onInput(email) {
			this.$emit(`input`, email);
		}
	}
};
</script>
