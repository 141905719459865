<template>
	<v-container>
		<v-row>
			<v-col cols="12" ms="8" md="8" lg="8" xl="8" class="pa-1 pa-sm-3 pr-sm-1">
				<v-card elevation="1" class="ma-sm-3 mr-sm-0">
					<v-card-title> {{ $t('change_email.title_label') }} </v-card-title>
					<v-card-text>
						<v-alert
							border="left"
							type="error"
							dense
							dismissible
							close-label="alert-current-email"
							class="body-2"
							:value="!!errorLabel.currentEmail"
							@input="errorLabel.currentEmail = null"
						>
							<span v-if="errorLabel.currentEmail">
								{{ $t(`errors.${errorLabel.currentEmail}`) }}
							</span>
						</v-alert>

						<v-text-field
							:value="email"
							prepend-icon="mdi-email"
							type="email"
							disabled
							class="pl-4 pr-4"
							:hint="$t('change_email.hint_before')"
							persistent-hint
						/>
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn
							color="primary"
							@click="enabled = true"
							:disabled="!!errorLabel.currentEmail || enabled"
							min-width="100px"
						>
							{{ $t('change_email.edit') }}
						</v-btn>
					</v-card-actions>
				</v-card>

				<v-scroll-y-transition>
					<v-card v-show="enabled" elevation="1" class="mt-1 ma-sm-3 mr-sm-0">
						<v-stepper v-model="step" alt-labels>
							<v-stepper-items>
								<v-stepper-content step="1" class="pa-0">
									<v-form v-model="valid" ref="form">
										<v-card-title> {{ $t('change_email.title') }} </v-card-title>
										<v-card-text>
											<v-alert
												border="left"
												type="error"
												dense
												dismissible
												close-label="alert-form"
												class="body-2"
												:value="!!errorLabel.form"
												@input="errorLabel.form = null"
											>
												<span v-if="errorLabel.form"> {{ $t(`errors.${errorLabel.form}`) }} </span>
											</v-alert>

											<EmailInput
												v-model="newEmail"
												:label="$t('change_email.label')"
												@blur="$refs.form.validate()"
												class="pl-4 pr-4"
												:hint="$t('change_email.hint_after')"
												:persistentHint="true"
											/>
										</v-card-text>
										<v-card-actions>
											<v-spacer />
											<v-btn color="secondary" @click="cancel" min-width="100px">
												{{ $t('change_email.cancel') }}
											</v-btn>
											<v-btn color="primary" @click="change" :disabled="!valid" min-width="100px">
												{{ $t('change_email.change') }}
											</v-btn>
										</v-card-actions>
									</v-form>
								</v-stepper-content>
								<v-stepper-content step="2" class="pa-0">
									<v-card-title> {{ $t('change_email.title') }} </v-card-title>
									<v-card-text>
										{{ $t('change_email.changed', { email: newEmail }) }}
									</v-card-text>
								</v-stepper-content>
							</v-stepper-items>
						</v-stepper>
					</v-card>
				</v-scroll-y-transition>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import EmailInput from '@/components/Input/EmailInput.vue';

export default {
	name: 'EmailChange',
	components: {
		EmailInput
	},
	data: () => ({
		enabled: false,
		valid: false,
		step: 1,
		errorLabel: {
			currentEmail: null,
			form: null
		},
		email: '',
		newEmail: ''
	}),

	async created() {
		await this.init();
	},
	methods: {
		async init() {
			try {
				const {
					data: { email }
				} = await this.$axios.oidc.get(`/api/v1/user`);
				this.email = email;
			} catch (e) {
				this.errorLabel.currentEmail = 'unknown';
			}
		},
		async change() {
			this.errorLabel.form = null;

			await this.$refs.form.validate();
			if (!this.valid) return;

			try {
				await this.$axios.oidc.put(`/api/v1/user/email`, {
					email: this.newEmail
				});
				this.setStep(2);
			} catch (e) {
				this.errorLabel.form = 'unknown';
				switch (e.response?.status) {
					case 409:
						this.errorLabel.form = 'same_email_registered';
						break;
					default:
						break;
				}
			}
		},
		async cancel() {
			this.enabled = false;
			this.errorLabel.form = null;
			await this.$refs.form.reset();
		},
		setStep(step) {
			this.step = step;
			this.$gtag.pageview(`${this.$route.path}?step=${step}`);
		}
	}
};
</script>
