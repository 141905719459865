export const GENDERS = [
	{
		value: 'female'
	},
	{
		value: 'male'
	},
	{
		value: 'custom'
	}
];

export const CUSTOM_GENDERS = [
	'Agender',
	'Androgyne',
	'Androgynous',
	'Bigender',
	'Cisgender',
	'Cisgender Female',
	'Cisgender Woman',
	'Cisgender Male',
	'Cisgender Man',
	'Cis',
	'Cis Female',
	'Cis Woman',
	'Cis Male',
	'Cis Man',
	'Female to Male',
	'Male to Female',
	'FTM',
	'MTF',
	'Gender Fluid',
	'Gender Nonconforming',
	'Gender Questioning',
	'Gender Variant',
	'Genderqueer',
	'Intersex',
	'Neither',
	'Neutrois',
	'Non-binary',
	'Other',
	'Pangender',
	'Trans',
	'Trans*',
	'Trans Female',
	'Trans* Female',
	'Trans Woman',
	'Trans* Woman',
	'Trans Male',
	'Trans* Male',
	'Trans Man',
	'Trans* Man',
	'Trans Person',
	'Trans* Person',
	'Transgender',
	'Transgender Female',
	'Transgender Woman',
	'Transgender Male',
	'Transgender Man',
	'Transgender Person',
	'Transfeminine',
	'Transmasculine',
	'Transsexual',
	'Transsexual Female',
	'Transsexual Woman',
	'Transsexual Male',
	'Trenssexual Man',
	'Trenssexual Person',
	'Two-spirit'
];
