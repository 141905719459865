<template>
	<v-container>
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card>
				<v-card-title> {{ $t('delete_account.dialog.title') }} </v-card-title>
				<v-card-text>
					<v-alert
						border="left"
						type="error"
						dense
						dismissible
						close-label="alert-dialog"
						class="body-2"
						:value="!!errorLabel.dialog"
						@input="errorLabel.dialog = null"
					>
						<span v-if="errorLabel.dialog"> {{ $t(`errors.${errorLabel.dialog}`) }} </span>
					</v-alert>

					{{ $t('delete_account.dialog.text_confirm') }}
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="secondary" @click="dialog = false" min-width="100px">
						{{ $t('delete_account.dialog.disagree_label') }}
					</v-btn>
					<v-btn color="error" @click="deleteAccount" min-width="100px">
						{{ $t('delete_account.dialog.agree_label') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-row>
			<v-col cols="12" md="8" lg="8" xl="8" class="pa-1 pa-sm-3 pr-sm-1">
				<v-card elevation="1" class="ma-sm-3 mr-sm-0">
					<v-card-title> {{ $t('delete_account.title') }} </v-card-title>
					<v-card-text>
						<v-alert
							border="left"
							type="error"
							dense
							dismissible
							close-label="alert-form"
							class="body-2"
							:value="!!errorLabel.form"
							@input="errorLabel.form = null"
						>
							<span v-if="errorLabel.form"> {{ $t(`errors.${errorLabel.form}`) }} </span>
						</v-alert>
						<v-form v-model="valid" ref="form">
							<div class="pb-3">
								{{ $t('delete_account.text') }}
							</div>
							<v-checkbox
								v-model="confirmDelCheck"
								:label="$t('delete_account.checkbox_label_delete')"
								:rules="rule"
							/>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn
							:disabled="!!errorLabel.form || !valid"
							color="error"
							@click="confirmDelAccount"
							min-width="100px"
						>
							{{ $t('delete_account.delete_account') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>

			<v-col cols="12" md="4" lg="4" xl="4" class="pa-1 pa-sm-3 pl-sm-1">
				<div class="ma-sm-3 ml-sm-0">
					<v-expansion-panels accordion outlined elevation="0" flat class="faq">
						<h3>{{ $t('delete_account.faq.title') }}</h3>
						<v-expansion-panel v-for="faq in faqs" :key="faq" class="grey lighten-5">
							<div style="border-bottom: 1px solid #ccc">
								<v-expansion-panel-header>
									{{ $t(`delete_account.faq.headers.${faq}`) }}
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									{{ $t(`delete_account.faq.items.${faq}`, { email: user.email }) }}
								</v-expansion-panel-content>
							</div>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from 'axios';

export default {
	name: 'LeaveAccount',
	data: () => ({
		user: {},
		errorLabel: {
			form: null,
			dialog: null
		},
		dialog: false,
		valid: false,
		confirmDelCheck: false,
		faqs: ['what_problems_occur', 'what_happen_personal_info']
	}),
	computed: {
		rule() {
			return [(v) => !!v || this.$t('errors.not_continue_delete_account')];
		}
	},
	async created() {
		await this.init();
	},
	methods: {
		async init() {
			try {
				const { data: user } = await this.$axios.oidc.get(`/api/v1/user`);
				this.user = user;
			} catch (e) {
				this.errorLabel.form = 'unknown';
			}
		},
		async deleteAccount() {
			await this.$refs.form.validate();
			if (!this.valid) return;

			try {
				await this.$axios.oidc.delete(`/api/v1/user`, {
					data: { accountId: this.user.accountId }
				});
				await axios.get(`/logout/`);

				this.dialog = false;
				this.$router.push({ path: '/notlogin' });
			} catch (e) {
				switch (e.response.status) {
					case 403:
						this.errorLabel.dialog = 'cannot_delete_primary_owner_user';
						break;
					default:
						this.errorLabel.dialog = 'unknown';
						break;
				}
			}
		},
		confirmDelAccount() {
			this.errorLabel.dialog = null;
			this.dialog = true;
		}
	}
};
</script>

<style lang="sass" scoped>
.faq
	justify-content: left !important
	h3
		font-size: 1rem
		color: #666
		font-weight: 500
	.v-expansion-panel-header
		padding: 16px 0
		color: #666
		font-size: 0.8rem
		font-weight: 500
	.v-expansion-panel-content
		&::v-deep
			.v-expansion-panel-content__wrap
				padding: 0 0 16px 0 !important
		color: #999
		font-size: 0.8rem
</style>
