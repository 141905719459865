<template>
	<v-container id="twofactor-auth">
		<v-row>
			<v-col cols="12" md="8" lg="8" xl="8" class="pa-1 pa-sm-3 pr-sm-1">
				<v-card elevation="1" class="ma-sm-3 mr-sm-0">
					<v-card-title> {{ $t('multi_factor_auth.title') }} </v-card-title>

					<v-card-text class="py-0">
						<v-alert
							border="left"
							type="error"
							dense
							dismissible
							close-label="alert"
							class="body-2"
							:value="!!errorLabel"
							@input="errorLabel = null"
						>
							<span v-if="errorLabel"> {{ $t(`errors.${errorLabel}`) }} </span>
						</v-alert>
						<div v-if="!errorLabel">
							{{ mfaEnabled ? $t('multi_factor_auth.enabled') : $t('multi_factor_auth.disabled') }}
						</div>
					</v-card-text>

					<v-card-actions>
						<v-spacer />
						<v-btn
							:color="mfaEnabled ? 'error' : 'primary'"
							min-width="100px"
							@click="onClick"
							:disabled="!!errorLabel || isEnableFlow || isDisableFlow"
						>
							{{
								mfaEnabled
									? $t('multi_factor_auth.button.disabled')
									: $t('multi_factor_auth.button.enabled')
							}}
						</v-btn>
					</v-card-actions>
				</v-card>

				<v-scroll-y-transition>
					<EnableFlow
						class="mt-1 ma-sm-3 mr-sm-0"
						v-if="isEnableFlow"
						@completed="updated"
						@setFlowType="setFlowType"
					/>
					<DisableFlow
						class="mt-1 ma-sm-3 mr-sm-0"
						v-if="isDisableFlow"
						:isMfaApp="isMfaApp"
						@completed="updated"
						@setFlowType="setFlowType"
					/>
				</v-scroll-y-transition>
			</v-col>

			<v-col cols="12" md="4" lg="4" xl="4" class="pa-1 pa-sm-3 pl-sm-1">
				<div class="ma-sm-3 ml-sm-0">
					<v-expansion-panels accordion outlined elevation="0" flat class="faq">
						<h3>{{ $t('multi_factor_auth.faq.title') }}</h3>
						<v-expansion-panel v-for="faq in faqs" :key="faq" class="grey lighten-5">
							<div style="border-bottom: 1px solid #ccc">
								<v-expansion-panel-header>
									{{ $t(`multi_factor_auth.faq.headers.${faq}`) }}
								</v-expansion-panel-header>
								<v-expansion-panel-content style="white-space: pre-wrap">
									{{ $t(`multi_factor_auth.faq.items.${faq}`) }}
								</v-expansion-panel-content>
							</div>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import DisableFlow from '@/components/TwoFactorAuth/DisableFlow.vue';
import EnableFlow from '@/components/TwoFactorAuth/EnableFlow.vue';

export default {
	name: 'TwofactorAuth',
	components: { DisableFlow, EnableFlow },
	data: () => ({
		user: {},
		errorLabel: null,
		error: null,
		flowType: '',
		qrcode: null,
		mfaType: null,
		mobileType: null,
		loading: false,
		token: '',
		isMfaApp: false,
		faqs: ['what_is_mfa', 'what_kind_of_app', 'how_i_disable', 'what_happen_lost']
	}),
	computed: {
		mfaEnabled() {
			return this.user.mfaEnabled;
		},
		isEnableFlow() {
			return this.flowType === 'enable';
		},
		isDisableFlow() {
			return this.flowType === 'disable';
		}
	},
	async created() {
		await this.init();
	},
	methods: {
		async init() {
			try {
				const { data: user } = await this.$axios.oidc.get(`/api/v1/user`);
				this.user = user;
			} catch (e) {
				this.errorLabel = 'unknown';
			}
		},
		onClick() {
			this.setFlowType(this.mfaEnabled ? 'disable' : 'enable');
			this.setIsMfaApp(this.user.mfaAppEnabled);
		},
		setFlowType(type) {
			this.flowType = type;
		},
		setIsMfaApp(flg) {
			this.isMfaApp = flg;
		},
		updated() {
			this.$emit('updated', 'mfa');
		}
	}
};
</script>
<style lang="sass" scoped>
.faq
	justify-content: left !important
	h3
		font-size: 1rem
		color: #666
		font-weight: 500
	.v-expansion-panel-header
		padding: 16px 0
		color: #666
		font-size: 0.8rem
		font-weight: 500
	.v-expansion-panel-content
		&::v-deep
			.v-expansion-panel-content__wrap
				padding: 0 0 16px 0 !important
		color: #999
		font-size: 0.8rem
.v-item-group
	.v-card.v-card--link.active
		background-color: #f0f0f0
	.v-card__title
		font-size: 1rem
		font-weight: bold
.text-caption.link
	a
		font-size: 0.20em
		color: grey
#twofactor-auth::v-deep
	.vue-pincode-input
		font-size: 2rem
		@media screen and (min-width: 600px)
			max-width: 50px
		@media screen and (max-width: 600px)
			width: 100%
</style>
