<template>
	<v-card elevation="1">
		<v-stepper v-model="step">
			<v-stepper-items v-if="isMfaApp">
				<v-stepper-content step="1" class="pa-0 pb-10">
					<v-card-title> {{ $t('multi_factor_auth.disable.title') }} </v-card-title>
					<v-card-subtitle>
						{{ $t('multi_factor_auth.disable.step1.subtitle') }}
					</v-card-subtitle>
					<v-card-text>
						<v-alert
							border="left"
							type="error"
							dense
							dismissible
							class="body-2"
							:value="!!errorLabel"
							@input="errorLabel = null"
						>
							{{ $t(`errors.${errorLabel}`) }}
						</v-alert>
						<v-row> <CodeInput v-model="token" /> </v-row>
					</v-card-text>
					<v-card-actions class="justify-end">
						<v-btn color="secondary" min-width="100px" @click="appCancel">
							{{ $t('multi_factor_auth.cancel') }}
						</v-btn>
						<v-btn
							color="primary"
							min-width="100px"
							@click="verify"
							:disabled="!token || token.length !== 6"
						>
							{{ $t('multi_factor_auth.next') }}
						</v-btn>
					</v-card-actions>
				</v-stepper-content>
				<v-stepper-content step="2" class="pa-0 pb-10">
					<v-card-title>{{ $t('multi_factor_auth.disable.title') }}</v-card-title>
					<v-card-text> {{ $t('multi_factor_auth.disable.message') }} </v-card-text>
				</v-stepper-content>
			</v-stepper-items>
			<v-stepper-items v-else>
				<v-stepper-content step="1" class="pa-0">
					<v-card-subtitle>
						{{ $t('multi_factor_auth.mail.send_mail') }}
					</v-card-subtitle>
					<v-card-actions class="justify-end">
						<v-btn color="primary" min-width="100px" @click="sendMail">
							{{ $t('multi_factor_auth.send_mail') }}
						</v-btn>
					</v-card-actions>
				</v-stepper-content>
				<v-stepper-content step="2" class="pa-0 pb-10">
					<v-card-title> {{ $t('multi_factor_auth.disable.title') }} </v-card-title>
					<v-card-subtitle>
						{{ $t('multi_factor_auth.mail.enter_code') }}
					</v-card-subtitle>
					<v-card-text>
						<v-alert
							border="left"
							type="error"
							dense
							dismissible
							class="body-2"
							:value="!!errorLabel"
							@input="errorLabel = null"
						>
							{{ $t(`errors.${errorLabel}`) }}
						</v-alert>
						<v-row>
							<CodeInput v-model="token" />
						</v-row>
					</v-card-text>
					<v-card-actions class="justify-end">
						<v-btn color="secondary" min-width="100px" @click="emailCancel">
							{{ $t('multi_factor_auth.prev') }}
						</v-btn>
						<v-btn
							color="primary"
							min-width="100px"
							@click="verify"
							:disabled="!token || token.length !== 6"
						>
							{{ $t('multi_factor_auth.next') }}
						</v-btn>
					</v-card-actions>
				</v-stepper-content>
				<v-stepper-content step="3" class="pa-0 pb-10">
					<v-card-title>{{ $t('multi_factor_auth.disable.title') }}</v-card-title>
					<v-card-text> {{ $t('multi_factor_auth.disable.message') }} </v-card-text>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</v-card>
</template>

<script>
import CodeInput from '@/components/Input/CodeInput.vue';

export default {
	name: 'DisableFlow',
	components: { CodeInput },
	props: {
		isMfaApp: {
			type: Boolean,
			default: false,
			required: true
		}
	},
	data: () => ({
		step: 1,
		errorLabel: null,
		loading: false,
		token: ''
	}),
	watch: {
		async token(_, oldValue) {
			if (this.token.length !== 6 || oldValue.length === 6) return;
			await this.verify();
		}
	},
	methods: {
		async verify() {
			try {
				this.loading = true;
				await this.$axios.oidc.delete(`/api/v1/user/mfa`, { data: { token: this.token } });
				this.setStep(this.step + 1);
				this.$emit('completed');
			} catch (e) {
				this.errorLabel = 'unknown';
				switch (e.response?.status) {
					case 400:
						this.errorLabel = 'verification_code';
						break;
					case 423:
						this.errorLabel = 'mfa_disable_locked';
						break;
					default:
				}
			} finally {
				this.loading = false;
			}
		},
		async sendMail() {
			try {
				this.loading = true;
				await this.$axios.oidc.post(`/api/v1/user/mfa/token/sendmail`);
				this.setStep(2);
			} catch (e) {
				this.errorLabel = 'unknown';
			} finally {
				this.loading = false;
			}
		},
		setStep(step) {
			this.step = step;
			this.$gtag.pageview(`${this.$route.path}?step=${step}`);
		},
		appCancel() {
			this.$emit('setFlowType', '');
			this.token = '';
			this.errorLabel = null;
		},
		emailCancel() {
			this.setStep(1);
			this.token = '';
			this.errorLabel = null;
		}
	}
};
</script>
