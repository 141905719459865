import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import * as Cookies from 'js-cookie';

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		accountToken: null,
		belongToOrganization: false
	},
	getters: {
		belongToOrganization: (state) => state.belongToOrganization
	},
	mutations: {
		setAccountToken(state, accountToken) {
			state.accountToken = accountToken;
		},
		setBelongToOrganization(state, belongToOrganization) {
			state.belongToOrganization = belongToOrganization;
		}
	},
	actions: {
		setAccountToken({ commit }, accountToken) {
			commit('setAccountToken', accountToken);
		},
		setBelongToOrganization({ commit }, belongToOrganization) {
			commit('setBelongToOrganization', belongToOrganization);
		}
	},
	plugins: [
		createPersistedState({
			key: 'session',
			storage: {
				getItem: (key) => {
					const accountToken = Cookies.get(key);
					if (!accountToken) return undefined;
					return JSON.stringify({ accountToken });
				},
				setItem: (key, value) => {
					const data = JSON.parse(value);
					const params = { secure: false };
					Cookies.set(key, data.accountToken, params);
				},
				removeItem: (key) => Cookies.remove(key)
			}
		})
	]
});

export default store;
