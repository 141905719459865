<template>
	<div>
		<v-row v-if="givenNamePreferred">
			<v-col cols="12" :md="cols" :sm="cols">
				<v-text-field
					:value="nameValue.givenName"
					:rules="givenNameRules"
					:counter="readonly ? null : maxlen"
					:label="$t('account_info.given_name')"
					:required="required"
					:clearable="clearable"
					:readonly="readonly"
					:disabled="disabled"
					:prepend-icon="singleLine ? 'mdi-account' : ''"
					@change="givenNameChanged"
					persistent-hint
					:hint="hint"
					:validate-on-blur="validateOnBlur"
					autocomplete="off"
					:tabindex="tabindex"
				/>
			</v-col>
			<v-col
				cols="12"
				:md="cols"
				:sm="cols"
				v-if="middleNameEnabled"
				class="pl-11 pl-md-3 pl-lg-3 pl-xg-3"
			>
				<v-text-field
					:value="nameValue.middleName"
					:counter="readonly ? null : maxlen"
					:label="$t('account_info.middle_name')"
					:clearable="clearable"
					persistent-hint
					:readonly="readonly"
					:disabled="disabled"
					@change="middleNameChanged"
					:validate-on-blur="validateOnBlur"
					autocomplete="off"
					:tabindex="tabindex + 1"
				/>
			</v-col>
			<v-col
				cols="12"
				:md="cols"
				:sm="cols"
				:class="{
					'pl-11': this.singleLine,
					'pl-md-3': this.singleLine,
					'pl-lg-3': this.singleLine,
					'pl-xg-3': this.singleLine
				}"
			>
				<v-text-field
					:value="nameValue.familyName"
					:rules="familyNameRules"
					:counter="readonly ? null : maxlen"
					:label="$t('account_info.family_name')"
					:required="required"
					:clearable="clearable"
					:readonly="readonly"
					:disabled="disabled"
					@change="familyNameChanged"
					:validate-on-blur="validateOnBlur"
					autocomplete="off"
					:tabindex="tabindex + 2"
				/>
			</v-col>
		</v-row>
		<v-row v-else>
			<v-col cols="12" :md="cols" :sm="cols">
				<v-text-field
					:prepend-icon="singleLine ? 'mdi-account' : ''"
					:value="nameValue.familyName"
					:rules="familyNameRules"
					:counter="readonly ? null : maxlen"
					:label="$t('account_info.family_name')"
					:required="required"
					:clearable="clearable"
					:readonly="readonly"
					:disabled="disabled"
					@change="familyNameChanged"
					persistent-hint
					:hint="hint"
					:validate-on-blur="validateOnBlur"
					autocomplete="off"
					:tabindex="tabindex"
				/>
			</v-col>
			<v-col
				cols="12"
				:md="cols"
				:sm="cols"
				v-if="middleNameEnabled"
				class="pl-11 pl-md-3 pl-lg-3 pl-xg-3"
			>
				<v-text-field
					:value="nameValue.middleName"
					:counter="readonly ? null : maxlen"
					:label="$t('account_info.middle_name')"
					:clearable="clearable"
					:hint="$t('account_info.optional')"
					persistent-hint
					:readonly="readonly"
					:disabled="disabled"
					@change="middleNameChanged"
					:validate-on-blur="validateOnBlur"
					autocomplete="off"
					:tabindex="tabindex + 1"
				/>
			</v-col>
			<v-col
				cols="12"
				:md="cols"
				:sm="cols"
				:class="{
					'pl-11': this.singleLine,
					'pl-md-3': this.singleLine,
					'pl-lg-3': this.singleLine,
					'pl-xg-3': this.singleLine
				}"
			>
				<v-text-field
					:value="nameValue.givenName"
					:rules="givenNameRules"
					:counter="readonly ? null : maxlen"
					:label="$t('account_info.given_name')"
					:required="required"
					:clearable="clearable"
					:readonly="readonly"
					:disabled="disabled"
					@change="givenNameChanged"
					:validate-on-blur="validateOnBlur"
					autocomplete="off"
					:tabindex="tabindex + 2"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-scroll-y-transition>
				<v-col cols="12" md="12" sm="12" v-show="nameValue.givenName && nameValue.familyName">
					<v-select
						:value="nameValue.name"
						prepend-icon="mdi-account-check"
						:items="fullNamePattern"
						:required="required"
						:clearable="clearable"
						:readonly="readonly"
						:disabled="disabled"
						:label="$t('account_info.full_name')"
						:rules="fullNameRules"
						:validate-on-blur="validateOnBlur"
						@change="fullNameChanged"
						:tabindex="tabindex + 3"
					/>
				</v-col>
			</v-scroll-y-transition>
		</v-row>
	</div>
</template>
<script>
export default {
	model: {
		prop: `nameValue`
	},
	props: {
		nameValue: {
			type: Object,
			required: false,
			default: () => ({
				givenName: null,
				familyName: null,
				middleName: null,
				name: null
			})
		},
		hint: {
			type: String,
			required: false,
			default: ''
		},
		tabindex: {
			type: Number,
			required: false,
			default: 0
		},
		required: {
			type: Boolean,
			required: false,
			default: true
		},
		clearable: {
			type: Boolean,
			required: false,
			default: true
		},
		singleLine: {
			type: Boolean,
			required: false,
			default: false
		},
		inputMiddleName: {
			type: Boolean,
			required: false,
			default: false
		},
		readonly: {
			type: Boolean,
			required: false,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		validateOnBlur: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => ({
		maxlen: 32
	}),
	computed: {
		// TODO 対応言語が増えた場合はここを修正
		middleNameEnabled() {
			if (!this.inputMiddleName) return false;
			if (this.$i18n.locale === 'en') return true;
			return false;
		},
		givenNamePreferred() {
			if (this.$i18n.locale === 'en') return true;
			return false;
		},
		givenNameRules() {
			return [
				(v) => !!v || this.$t('errors.required', { key: this.$t('account_info.given_name') }),
				(v) =>
					(v && v.length <= this.maxlen) ||
					this.$t('errors.maxlen', { key: this.$t('account_info.given_name'), maxlen: this.maxlen })
			];
		},
		familyNameRules() {
			return [
				(v) => !!v || this.$t('errors.required', { key: this.$t('account_info.family_name') }),
				(v) =>
					(v && v.length <= this.maxlen) ||
					this.$t('errors.maxlen', {
						key: this.$t('account_info.family_name'),
						maxlen: this.maxlen
					})
			];
		},
		fullNameRules() {
			return [(v) => !!v || this.$t('errors.required', { key: this.$t('account_info.full_name') })];
		},
		cols() {
			if (!this.singleLine) return 12;
			return this.givenNamePreferred ? 4 : 6;
		},
		fullNamePattern() {
			const pattern = [];
			if (!this.nameValue.familyName || !this.nameValue.givenName) return [];
			pattern.push(
				`${this.nameValue.familyName} ${this.nameValue.givenName}`,
				`${this.nameValue.familyName}, ${this.nameValue.givenName}`,
				`${this.nameValue.givenName} ${this.nameValue.familyName}`,
				`${this.nameValue.givenName}, ${this.nameValue.familyName}`
			);
			if (this.middleNameEnabled && this.nameValue.middleName) {
				pattern.push(
					`${this.nameValue.givenName}, ${this.nameValue.familyName} ${this.nameValue.middleName
						.slice(0, 1)
						.toUpperCase()}`,
					`${this.nameValue.givenName} ${this.nameValue.middleName.slice(0, 1).toUpperCase()} ${
						this.nameValue.familyName
					} `,
					`${this.nameValue.givenName} ${this.nameValue.middleName} ${this.nameValue.familyName}`,
					`${this.nameValue.familyName}, ${this.nameValue.givenName} ${this.nameValue.middleName}`
				);
			}
			return pattern;
		}
	},
	methods: {
		givenNameChanged(value) {
			this.$emit(`input`, {
				givenName: value,
				familyName: this.nameValue.familyName,
				middleName: this.nameValue.middleName,
				name: null
			});
		},
		familyNameChanged(value) {
			this.$emit(`input`, {
				givenName: this.nameValue.givenName,
				familyName: value,
				middleName: this.nameValue.middleName,
				name: null
			});
		},
		middleNameChanged(value) {
			this.$emit(`input`, {
				givenName: this.nameValue.givenName,
				familyName: this.nameValue.familyName,
				middleName: this.middleNameEnabled && value ? value : null,
				name: null
			});
		},
		fullNameChanged(value) {
			this.$emit(`input`, {
				givenName: this.nameValue.givenName,
				familyName: this.nameValue.familyName,
				middleName: this.nameValue.middleName,
				name: value
			});
		}
	}
};
</script>
