<template>
	<div>
		<v-avatar :size="avatorSize" v-if="picture">
			<img alt="picture" :src="picture" />
		</v-avatar>
		<v-avatar :size="avatorSize" v-else>
			<v-icon :size="avatorSize"> mdi-account-circle </v-icon>
		</v-avatar>
	</div>
</template>

<script>
export default {
	name: 'ProfileImage',
	model: {
		prop: `picture`
	},
	props: {
		picture: {
			type: String,
			default: ''
		},
		avatorSize: {
			type: Number,
			default: 120
		}
	}
};
</script>
