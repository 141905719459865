<template>
	<div>
		<v-menu v-model="showPicker" :close-on-content-click="false" min-width="290px">
			<template v-slot:activator="{ on, attrs }">
				<v-text-field
					:value="formatedDate"
					:label="label"
					prepend-icon="mdi-calendar-check"
					:readonly="readonly"
					:disabled="disabled"
					v-bind="attrs"
					v-on="on"
					:rules="rules"
					:clearable="clearable"
				/>
			</template>

			<v-date-picker
				:value="pickerDate"
				@input="onInput"
				:locale="$i18n.locale"
				:readonly="readonly"
				:disabled="disabled"
				:min="minDate"
				:max="maxDate"
				:day-format="(date) => new Date(date).getDate()"
			/>
		</v-menu>
	</div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
	model: {
		prop: `date`
	},
	props: {
		date: {
			type: String,
			required: false,
			default: null
		},
		label: {
			type: String,
			required: false,
			default: 'birth date'
		},
		readonly: {
			type: Boolean,
			required: false,
			default: false
		},
		clearable: {
			type: Boolean,
			required: false,
			default: false
		},
		stop: {
			type: Boolean,
			required: false,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		disableValidation: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => ({
		showPicker: false,
		maxDate: DateTime.now().plus({ years: -10 }).toFormat('yyyy-12-31'),
		minDate: DateTime.now().plus({ years: -100 }).toFormat('yyyy-01-01')
	}),
	computed: {
		pickerDate() {
			if (this.date) return this.date;
			return DateTime.now().plus({ years: -30 }).toFormat('yyyy-01-01');
		},
		formatedDate() {
			if (this.date)
				return DateTime.fromFormat(this.date, 'yyyy-MM-dd')
					.setLocale(this.$i18n.locale)
					.toLocaleString(DateTime.DATE_FULL);
			return null;
		},
		rules() {
			return [
				(v) => this.disableValidation || !!v || this.$t('errors.required', { key: this.label })
			];
		}
	},
	methods: {
		onInput(value) {
			this.showPicker = false;
			this.$emit('input', value);
		}
	}
};
</script>
