<template>
	<v-container>
		<v-row v-if="display" justify="space-around">
			<v-col v-if="!errorLabel" cols="12" align="center">
				<v-card class="ma-3" max-width="600">
					<v-card-text>
						<v-row>
							<v-col align="center" cols="12">
								<v-icon x-large>mdi-check-circle-outline</v-icon>
							</v-col>
							<v-col align="center" class="pt-0">
								<v-card-title>
									<div style="flex: auto">
										{{ $t('change_email.verify_title') }}
									</div>
								</v-card-title>
								<v-card-subtitle>{{ $t('change_email.verify_subtitle') }}</v-card-subtitle>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col v-else align="center" cols="12" ms="8" md="8" lg="8" xl="8">
				<v-alert
					align="start"
					border="left"
					type="error"
					dense
					class="text-subtitle-2 ma-3"
					max-width="600px"
				>
					<span v-if="errorLabel"> {{ $t(`errors.${errorLabel}`) }} </span>
				</v-alert>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'EmailVerify',
	components: {},
	data: () => ({
		errorLabel: null,
		display: false,
		code: ''
	}),
	async created() {
		if (!this.$route.params.code) {
			this.errorLabel = 'verification_code';
			this.display = true;
			return;
		}
		await this.verify();
		this.display = true;
	},
	methods: {
		async verify() {
			try {
				await this.$axios.oidc.post(`/api/v1/user/email/verify`, {
					code: this.$route.params.code
				});
			} catch (e) {
				this.errorLabel = 'unknown';
				switch (e.response?.status) {
					case 400:
						this.errorLabel = 'verification_code';
						break;
					case 404:
						this.errorLabel = 'verification_code';
						break;
					case 409:
						this.errorLabel = 'existing_email';
						break;
					default:
				}
			}
		}
	}
};
</script>
