<template>
	<div class="password">
		<v-text-field
			v-bind:value="value"
			@input="onInput"
			:rules="rules"
			:label="label"
			:required="required"
			prepend-icon="mdi-lock"
			:append-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
			@click:append="() => (isPasswordVisible = !isPasswordVisible)"
			:type="isPasswordVisible ? 'text' : 'password'"
			persistent-hint
			:validate-on-blur="validateOnBlur"
			:counter="maxlen"
			:clearable="clearable"
			:tabindex="tabindex"
			:hint="hint"
		/>
		<div style="margin: 10px 0 0 32px" class="align-center" v-if="strengthMeter">
			<v-progress-linear v-model="passwordScore" height="3" :color="strengthColor" />
			<div class="caption" style="text-align: center">
				<span v-if="password">{{ $t(`password_strengths.${strengthText}`) }}</span>
				<span v-else>&nbsp;</span>
			</div>
		</div>
	</div>
</template>
<script>
import isStrongPassword from 'validator/lib/isStrongPassword';

export default {
	model: {
		prop: `value`
	},
	props: {
		value: {
			type: String,
			default: ''
		},
		validateOnBlur: {
			type: Boolean,
			default: true
		},
		label: {
			type: String,
			default: 'Password'
		},
		required: {
			type: Boolean,
			default: false
		},
		clearable: {
			type: Boolean,
			default: false
		},
		hint: {
			type: String,
			default: ''
		},
		disableValidation: {
			type: Boolean,
			default: false
		},
		strengthMeter: {
			type: Boolean,
			default: false
		},
		tabindex: {
			type: Number,
			default: 0
		},
		minlen: {
			type: Number,
			default: 8
		},
		maxlen: {
			type: Number,
			default: 32
		}
	},
	data: () => ({
		password: '',
		isPasswordVisible: false,
		options: {
			minLength: 8,
			minLowercase: 1,
			minUppercase: 0,
			minNumbers: 1,
			minSymbols: 0,
			returnScore: false,
			pointsPerUnique: 4,
			pointsPerRepeat: 1,
			pointsForContainingLower: 8,
			pointsForContainingUpper: 16,
			pointsForContainingNumber: 4,
			pointsForContainingSymbol: 24
		}
	}),
	computed: {
		strengthColor() {
			if (!this.password) return '#fff';
			const score = isStrongPassword(this.password, { ...this.options, returnScore: true });
			if (score < 10) return '#f00505';
			if (score < 20) return '#ff2c05';
			if (score < 30) return '#fd6104';
			if (score < 40) return '#fd9a01';
			if (score < 50) return '#ffce03';
			if (score < 60) return '#fef001';
			if (score < 70) return '#cefb02';
			if (score < 80) return '#87fa00';
			if (score < 90) return '#3af901';
			return '#39C16C';
		},
		strengthText() {
			const score = isStrongPassword(this.password, { ...this.options, returnScore: true });
			if (score <= 20) return 'very_weak';
			if (score <= 40) return 'weak';
			if (score <= 60) return 'medium';
			if (score <= 80) return 'strong';
			return 'very_strong';
		},
		passwordScore() {
			if (!this.password) return 0;
			return isStrongPassword(this.password, { ...this.options, returnScore: true });
		},
		rules() {
			if (this.disableValidation)
				return [(v) => !!v || this.$t('errors.required', { key: this.label })];
			return [
				(v) => !!v || this.$t('errors.required', { key: this.label }),
				(v) =>
					(v && v.length >= this.minlen) ||
					this.$t('errors.minlen', { key: this.label, minlen: this.minlen }),
				(v) =>
					(v && v.length <= this.maxlen) ||
					this.$t('errors.maxlen', { key: this.label, maxlen: this.maxlen })
				// (v) => isStrongPassword(v, this.options) || this.hint
			];
		}
	},

	methods: {
		onInput(password) {
			this.password = password;
			this.$emit(`input`, password);
		}
	}
};
</script>
<style lang="sass" scoped></style>
