<template>
	<v-container>
		<v-row>
			<v-col cols="12" sm="8" md="8" lg="8" xl="8" class="pa-1 pa-sm-3">
				<v-card class="ma-sm-3 mr-sm-0">
					<v-card-title> {{ $t('organization_invited.title_invites') }} </v-card-title>
					<v-card-text>
						<v-alert
							border="left"
							:type="messageType"
							dense
							dismissible
							close-label="alert"
							class="body-2"
							:value="!!message"
							@input="message = null"
						>
							<span v-if="message"> {{ message }} </span>
						</v-alert>

						<v-data-table
							:headers="inviteHeaders"
							:items="invites"
							:items-per-page="10"
							:no-data-text="$t('organization_invited.no_data_text')"
							:footer-props="footerProps"
							:mobile-breakpoint="960"
						>
							<template #[`item.inviterName`]="{ item }">
								<v-avatar :size="24" class="mr-2">
									<v-img :src="item.inviterPicture" />
								</v-avatar>

								<span>{{ item.inviterName }}</span>
							</template>

							<template #[`item.createdAt`]="{ item }">
								<span>{{ formatedDate(item.createdAt) }}</span>
							</template>

							<template #[`item.action`]="{ item }">
								<v-chip class="ma-2" color="error" @click="openDialog(item)" small>
									<v-icon left>mdi-alert-box-outline</v-icon>
									{{ $t('organization_invited.table.row.unsupported') }}
								</v-chip>
							</template>

							<template #[`footer.pageText`]="{ pageStart }">
								{{ pageStart }}
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>

				<v-dialog v-model="dialog" max-width="600px">
					<v-card>
						<v-system-bar>
							<v-spacer></v-spacer>
							<v-icon @click="dialog = false">mdi-close</v-icon>
						</v-system-bar>
						<v-card-title> {{ $t('organization_invited.dialog.title') }} </v-card-title>
						<v-card-text>
							{{ $t('organization_invited.dialog.text', { organizationName }) }}
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="error" @click="submit(false)" min-width="100px">
								{{ $t('organization_invited.dialog.disagree_label') }}
							</v-btn>
							<v-btn color="primary" @click="submit(true)" min-width="100px">
								{{ $t('organization_invited.dialog.agree_label') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import moment from 'moment';

export default {
	name: 'InvitedOrganization',
	data: () => ({
		invites: [],
		dialog: false,
		inviteId: null,
		organizationName: '',
		message: '',
		messageType: 'success',
		counter: {
			incrementPerSeconds: 60,
			value: 0,
			intervalId: null
		}
	}),
	computed: {
		formatedDate() {
			// eslint-disable-next-line no-unused-expressions
			this.counter.value;
			return (createdAt) => {
				const momentDate = moment.unix(createdAt);
				if (moment().diff(momentDate, 'seconds') < 60)
					return `< ${momentDate.locale(this.$i18n.locale).fromNow()}`;
				return momentDate.locale(this.$i18n.locale).fromNow();
			};
		},
		footerProps() {
			return {
				itemsPerPageOptions: [10, 50, 100]
			};
		},
		inviteHeaders() {
			return [
				{
					text: this.$t('organization_invited.table.header.status'),
					value: 'action',
					align: 'center',
					sortable: false
				},
				{
					text: this.$t('organization_invited.table.header.organization_name'),
					value: 'organizationName',
					align: 'center',
					sortable: false
				},
				{
					text: this.$t('organization_invited.table.header.inviter_name'),
					value: 'inviterName',
					align: 'center',
					sortable: false
				},
				{
					text: this.$t('organization_invited.table.header.inviter_email'),
					value: 'inviterEmail',
					align: 'center',
					sortable: false
				},
				{
					text: this.$t('organization_invited.table.header.created_at'),
					value: 'createdAt',
					align: 'center',
					sortable: false
				}
			];
		}
	},
	async created() {
		this.configureMoment();
		this.setIntervalIncrementCounter();
		await this.fetchInvites();
	},
	destroyed() {
		clearInterval(this.counter.intervalId);
	},
	methods: {
		async fetchInvites() {
			const {
				data: { invites }
			} = await this.$axios.oidc.get('/api/v1/organization/invites');
			this.invites = invites;
		},
		async getOrganizations() {
			const {
				data: { total }
			} = await this.$axios.oidc.get(`/api/v1/organizations`, { params: { limit: 1 } });
			this.$store.dispatch('setBelongToOrganization', !!total);
		},
		async submit(accepted) {
			try {
				await this.$axios.oidc.put(`/api/v1/organization/invite/${this.inviteId}`, {
					accepted
				});
				await this.getOrganizations();
				await this.fetchInvites();

				this.message = accepted
					? this.$t('organization_invited.messages.accept')
					: this.$t('organization_invited.messages.reject');
				this.messageType = 'success';
			} catch (e) {
				this.message = this.$t('errors.unknown');
				this.messageType = 'error';
			} finally {
				this.dialog = false;
			}
		},
		setIntervalIncrementCounter() {
			this.counter.intervalId = setInterval(() => {
				this.counter.value += 1;
			}, this.counter.incrementPerSeconds * 1000);
		},
		configureMoment() {
			moment.relativeTimeThreshold('s', 0);
			moment.updateLocale('en', {
				relativeTime: {
					m: 'mins',
					mm: '%d mins'
				}
			});
		},
		openDialog(item) {
			this.message = null;
			this.dialog = true;
			this.inviteId = item.id;
			this.organizationName = item.organizationName;
		}
	}
};
</script>
