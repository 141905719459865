<template>
	<div>
		<v-stepper-content step="2" class="pa-0">
			<v-card-subtitle>
				{{ $t('multi_factor_auth.activate.step2.subtitle') }}
			</v-card-subtitle>
			<v-card-text>
				<v-item-group v-model="mobileType">
					<v-container>
						<v-row>
							<v-col cols="12" md="6">
								<v-item v-slot="{ active, toggle }" value="ios">
									<v-card :color="active ? activeColor : ''" @click="toggle" outlined>
										<v-card-title>
											<v-icon>mdi-apple</v-icon>
											{{ $t('multi_factor_auth.activate.step2.list.ios.title') }}
										</v-card-title>
									</v-card>
								</v-item>
							</v-col>
							<v-col cols="12" md="6">
								<v-item v-slot="{ active, toggle }" value="android">
									<v-card :color="active ? activeColor : ''" @click="toggle" outlined>
										<v-card-title>
											<v-icon>mdi-android</v-icon>
											{{ $t('multi_factor_auth.activate.step2.list.android.title') }}
										</v-card-title>
									</v-card>
								</v-item>
							</v-col>
						</v-row>
						<v-scroll-y-transition>
							<v-row v-if="mobileType === 'ios'">
								<v-col cols="12" md="12">
									<v-card flat>
										<v-card-text class="justify-center">
											<a
												:href="$t('multi_factor_auth.activate.step2.list.ios.link')"
												target="_blank"
												aria-label="ios link"
											>
												<v-img
													v-bind:src="require('@/assets/app_store.svg')"
													height="50px"
													contain
												/>
											</a>
											<v-img
												v-bind:src="require('@/assets/qr_authenticator_ios.png')"
												height="300"
												contain
												class="d-none d-md-block"
											/>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
							<v-row v-if="mobileType === 'android'">
								<v-col cols="12" md="12">
									<v-card flat>
										<v-card-text>
											<a
												:href="$t('multi_factor_auth.activate.step2.list.android.link')"
												target="_blank"
												aria-label="android link"
											>
												<v-img
													v-bind:src="require('@/assets/google_play.svg')"
													height="50px"
													contain
												/>
											</a>
											<v-img
												v-bind:src="require('@/assets/qr_authenticator_android.png')"
												height="300"
												contain
												class="d-none d-md-block"
											/>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
						</v-scroll-y-transition>
					</v-container>
				</v-item-group>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn color="secondary" min-width="100px" @click="$emit('setStep', 1)">
					{{ $t('multi_factor_auth.prev') }}
				</v-btn>
				<v-btn color="primary" min-width="100px" @click="getQrcode" :disabled="!mobileType">
					{{ $t('multi_factor_auth.next') }}
				</v-btn>
			</v-card-actions>
		</v-stepper-content>
		<v-stepper-content step="3" class="pa-0">
			<v-card-title> {{ $t('multi_factor_auth.activate.title') }} </v-card-title>
			<v-card-subtitle>
				{{ $t('multi_factor_auth.activate.step3.subtitle') }}
			</v-card-subtitle>
			<v-card-text>
				<v-row>
					<v-col>
						<v-img :src="qrcode" height="300" contain />
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn color="secondary" min-width="100px" @click="$emit('setStep', 2)">
					{{ $t('multi_factor_auth.prev') }}
				</v-btn>
				<v-btn color="primary" min-width="100px" @click="$emit('setStep', 4)">
					{{ $t('multi_factor_auth.next') }}
				</v-btn>
			</v-card-actions>
		</v-stepper-content>
		<v-stepper-content step="4" class="pa-0">
			<v-card-title> {{ $t('multi_factor_auth.activate.title') }} </v-card-title>
			<v-card-subtitle>
				{{ $t('multi_factor_auth.activate.step4.subtitle') }}
			</v-card-subtitle>
			<v-card-text>
				<v-alert
					border="left"
					type="error"
					dense
					dismissible
					class="body-2"
					:value="!!errorLabel"
					@input="errorLabel = null"
				>
					{{ $t(`errors.${errorLabel}`) }}
				</v-alert>
				<v-row>
					<CodeInput v-model="token" />
				</v-row>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn color="secondary" min-width="100px" @click="resetCode(3)" :disabled="failOver">
					{{ $t('multi_factor_auth.prev') }}
				</v-btn>
				<v-btn
					v-if="!failOver"
					color="primary"
					min-width="100px"
					@click="verify"
					:disabled="!token || token.length !== 6"
				>
					{{ $t('multi_factor_auth.next') }}
				</v-btn>
				<v-btn v-else color="primary" min-width="100px" @click="resetCode(1)">
					{{ $t('multi_factor_auth.to_first_step') }}
				</v-btn>
			</v-card-actions>
		</v-stepper-content>
		<v-stepper-content step="5" class="pa-0 pb-10">
			<v-card-title>{{ $t('multi_factor_auth.activate.title') }}</v-card-title>
			<v-card-text> {{ $t('multi_factor_auth.activate.step5.message') }} </v-card-text>
		</v-stepper-content>
	</div>
</template>

<script>
import CodeInput from '@/components/Input/CodeInput.vue';

export default {
	name: 'MobileStep',
	components: {
		CodeInput
	},
	props: {
		activeColor: {
			type: String,
			default: '',
			required: true
		},
		step: {
			type: Number,
			default: 0,
			required: true
		}
	},
	data: () => ({
		token: '',
		mobileType: '',
		qrcode: '',
		failCount: 0,
		loading: false,
		errorLabel: null
	}),
	computed: {
		failOver() {
			return this.failCount >= 3;
		}
	},
	watch: {
		token(_, oldValue) {
			if (this.token.length !== 6 || oldValue.length === 6) return;
			this.verify();
		}
	},
	methods: {
		async verify() {
			try {
				this.loading = true;
				await this.$axios.oidc.post(`/api/v1/user/mfa/verify`, {
					token: this.token
				});
				this.$emit('completed');
				this.$emit('setStep', 5);
			} catch (e) {
				this.errorLabel = 'unknown';
				switch (e.response?.status) {
					case 400:
						this.failCount += 1;
						this.errorLabel = 'verification_code';
						if (this.failOver) this.errorLabel = 'verification_code_fail';
						break;
					default:
				}
			} finally {
				this.loading = false;
			}
		},
		async getQrcode() {
			if (this.qrcode) {
				this.$emit('setStep', 3);
				return;
			}
			try {
				this.loading = true;
				const {
					data: { qrcode }
				} = await this.$axios.oidc.post(`/api/v1/user/mfa`, { appEnabled: true });
				this.qrcode = qrcode;
				this.$emit('setStep', 3);
			} catch (e) {
				this.errorLabel = 'unknown';
			} finally {
				this.loading = false;
			}
		},
		resetCode(step) {
			this.$emit('setStep', step);
			this.token = '';
			this.failCount = 0;
			this.errorLabel = '';
		}
	}
};
</script>
