<template>
	<div>
		<v-tooltip bottom>
			<template v-slot:activator="{ on: onTooltop, attrs: attrsTooltip }">
				<v-badge
					color="red"
					:content="unreadCount"
					:value="!!unreadCount"
					label="notification-bell-badge"
				>
					<v-menu v-model="menu" bottom left offset-y :close-on-content-click="false">
						<template #activator="{ on: onBadge, attrs: attrsBadge }">
							<v-icon v-bind="[attrsTooltip, attrsBadge]" v-on="{ ...onTooltop, ...onBadge }">
								mdi-dark mdi-bell
							</v-icon>
						</template>

						<v-card>
							<v-card-title class="pb-0 justify-space-between">
								{{ $t('components.notification_bell.card.title') }}

								<div class="d-flex">
									<v-chip v-if="notifications.length" outlined pill small @click="allRead">
										{{ $t('components.notification_bell.card.all_read') }}
										<v-icon right small>mdi-dark mdi-check-circle-outline</v-icon>
									</v-chip>
								</div>
							</v-card-title>
							<v-card-text>
								<v-alert
									border="left"
									type="error"
									dense
									class="body-2 mt-3 mb-0"
									:value="!!errorLabel"
									@input="errorLabel = null"
								>
									<span v-if="!!errorLabel">
										{{ $t(`components.notification_bell.errors.${errorLabel}`) }}
									</span>
								</v-alert>
							</v-card-text>

							<v-divider />

							<v-card-text v-if="!notifications.length" class="d-flex justify-space-between">
								{{ $t('components.notification_bell.card.not_data') }}
								<v-chip
									v-if="!notifications.length"
									outlined
									pill
									small
									@click="loadNotifications(limit, { loading: true })"
								>
									{{ $t('components.notification_bell.card.reload') }}
									<v-icon right small>mdi-dark mdi-cached</v-icon>
								</v-chip>
							</v-card-text>
							<v-list v-else class="pa-0">
								<v-expansion-panels accordion>
									<v-expansion-panel v-for="(notification, index) in notifications" :key="index">
										<v-list-item
											:key="notification.title"
											:class="[notification.read ? '' : 'unread']"
										>
											<v-list-item-content>
												<v-expansion-panel-header>
													{{ notification.title }}
												</v-expansion-panel-header>
												<v-expansion-panel-content>
													{{ notification.message }}
												</v-expansion-panel-content>
											</v-list-item-content>

											<v-list-item-action class="align-self-center ml-0">
												<v-list-item-action-text>
													{{ formatedDate(notification.createdAt) }}
												</v-list-item-action-text>

												<v-icon
													v-if="notification.read"
													color="secondary lighten-2"
													@click="changeRead(notification.notificationId, false)"
												>
													mdi-check-circle-outline
												</v-icon>
												<v-icon
													v-else
													color="secondary"
													@click="changeRead(notification.notificationId, true)"
												>
													mdi-checkbox-blank-circle-outline
												</v-icon>
											</v-list-item-action>
										</v-list-item>
									</v-expansion-panel>
								</v-expansion-panels>
								<div v-intersect="onIntersect"></div>
							</v-list>
							<v-progress-linear v-show="loading" indeterminate />
						</v-card>
					</v-menu>
				</v-badge>
			</template>
			<span>{{ $t('components.notification_bell.tooltip') }}</span>
		</v-tooltip>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	name: 'NotificationBell',
	props: {
		limit: {
			type: Number,
			default: 10,
			required: false
		},
		limitOfIntervalLoad: {
			type: Number,
			default: 10,
			required: false
		},
		intervalSeconds: {
			type: Number,
			default: 60,
			required: false,
			validator(value) {
				return value >= 60;
			}
		}
	},
	data: () => ({
		errorLabel: null,
		unreadCount: 0,
		menu: false,
		notifications: [],
		offset: 0,
		loading: false,
		loadNotificationsIntervalId: null,
		counter: {
			incrementPerSeconds: 60,
			value: 0,
			intervalId: null
		},
		minimumUlid: '0001FNW400EXPHRASQ504YTRGZ'
	}),
	computed: {
		formatedDate() {
			// eslint-disable-next-line no-unused-expressions
			this.counter.value;
			return (date) => {
				const momentDate = moment.unix(date);

				if (moment().diff(momentDate, 'seconds') < 60)
					return `< ${momentDate.locale(this.$i18n.locale).fromNow()}`;
				return momentDate.locale(this.$i18n.locale).fromNow();
			};
		}
	},
	watch: {
		// eslint-disable-next-line no-unused-vars
		menu(newValue, oldValue) {
			if (newValue) this.setIntervalIncrementCounter();
			else clearInterval(this.counter.intervalId);
		},
		'$i18n.locale': {
			async handler() {
				this.loadNotifications(this.limit, { loading: false });
			}
		}
	},
	async created() {
		this.configureMoment();

		await this.getUnreadConut();
		await this.loadNotifications(this.limit, { loading: false });
	},
	destroyed() {
		clearInterval(this.loadNotificationsIntervalId);
		clearInterval(this.counter.intervalId);
	},
	methods: {
		async getUnreadConut() {
			try {
				const {
					data: { total }
				} = await this.$axios.notification.get(`/api/v1/notifications`, {
					params: { countOnly: true, read: false }
				});
				this.unreadCount = total;
			} catch (e) {
				this.errorLabel = 'not_load';
			}
		},
		async loadNotifications(limit, options = {}) {
			if (options.loading) this.loading = true;

			this.notifications.length = 0;
			this.clearError();

			try {
				const {
					data: { notifications }
				} = await this.$axios.notification.get(`/api/v1/notifications`, {
					params: { limit, locale: this.$i18n.locale }
				});
				this.notifications = notifications;

				if (!this.loadNotificationsIntervalId) this.setIntervalLoadNotifications();
			} catch (e) {
				this.errorLabel = 'not_load';
			} finally {
				this.loading = false;
			}
		},
		async changeRead(notificationId, read) {
			this.clearError();

			try {
				await this.$axios.notification.put(`/api/v1/notification/${notificationId}`, { read });

				this.unreadCount += read ? -1 : 1;
				this.notifications
					.filter((notification) => notification.notificationId === notificationId)
					.forEach((notification) => {
						const v = notification;
						v.read = read;
					});
			} catch (e) {
				this.errorLabel = 'unknown';
			}
		},
		async allRead() {
			this.clearError();

			try {
				await this.$axios.notification.put(`/api/v1/notifications`);
				await this.getUnreadConut();
				await this.loadNotifications(this.notifications.length || this.limit, { loading: false });
			} catch (e) {
				this.errorLabel = 'unknown';
			}
		},
		setIntervalLoadNotifications() {
			this.loadNotificationsIntervalId = setInterval(async () => {
				this.clearError();

				try {
					const {
						data: { notifications }
					} = await this.$axios.notification.get(`/api/v1/notifications`, {
						params: { limit: this.limitOfIntervalLoad }
					});
					notifications
						.filter((newNotification) => {
							const latestNotificationUlid = this.notifications.length
								? this.notifications[0].notificationId
								: this.minimumUlid;
							return newNotification.notificationId > latestNotificationUlid;
						})
						.forEach((newNotification) => {
							this.notifications.unshift(newNotification);
							this.unreadCount += 1;
						});
				} catch (error) {
					this.errorLabel = 'not_load';
				}
			}, this.intervalSeconds * 1000);
		},
		setIntervalIncrementCounter() {
			this.counter.intervalId = setInterval(() => {
				this.counter.value += 1;
			}, this.counter.incrementPerSeconds * 1000);
		},
		async onIntersect(entries, observer, isIntersecting) {
			if (isIntersecting) {
				this.loading = true;
				this.clearError();
				this.offset = this.notifications.length;

				try {
					const {
						data: { notifications }
					} = await this.$axios.notification.get(`/api/v1/notifications`, {
						params: {
							limit: this.limit,
							offset: this.offset
						}
					});
					this.notifications = this.notifications.concat(notifications);
				} catch (e) {
					this.errorLabel = 'not_load';
				} finally {
					this.loading = false;
				}
			}
		},
		configureMoment() {
			moment.relativeTimeThreshold('s', 0);
			moment.updateLocale('en', {
				relativeTime: {
					m: 'mins',
					mm: '%d mins'
				}
			});
		},
		clearError() {
			this.errorLabel = null;
		}
	}
};
</script>

<style lang="sass" scoped>
.v-badge::v-deep
	.v-badge__badge
		inset: auto auto calc(100% - 8px) calc(100% - 8px) !important

.v-menu__content
	width: 400px

.v-list
	overflow: auto
	max-height: 300px

.v-expansion-panel::before
	box-shadow: none

.v-list-item__action--stack
	width: 45px

.v-list-item__action-text
	font-size: 0.5rem

.v-expansion-panel-header
	padding: 16px 12px 8px
	color: #666
	min-height: 36px
	font-size: 0.8rem

.v-expansion-panel-content
	&::v-deep
		.v-expansion-panel-content__wrap
			padding: 0 12px 12px
	color: #999
	font-size: 0.8rem

.unread
	font-weight: bold
</style>
