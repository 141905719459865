<template>
	<v-card elevation="1">
		<v-stepper v-model="step">
			<v-stepper-header style="box-shadow: none">
				<v-stepper-step :complete="step > 1" step="1" />
				<v-divider />
				<v-stepper-step :complete="step > 2" step="2" />
				<v-divider />
				<v-stepper-step :complete="step > 3" step="3" />
				<v-divider />
				<v-stepper-step :complete="step > 4" step="4" />
				<v-divider v-if="isMfaApp" />
				<v-stepper-step v-if="isMfaApp" :complete="step > 5" step="5" />
			</v-stepper-header>
			<v-stepper-items>
				<v-stepper-content step="1" class="pa-0">
					<v-card-title> {{ $t('multi_factor_auth.activate.title') }} </v-card-title>
					<v-card-subtitle>
						{{ $t('multi_factor_auth.activate.step1.subtitle') }}
					</v-card-subtitle>
					<v-card-text>
						<v-item-group v-model="mfaType" mandatory>
							<v-container>
								<v-row>
									<v-col>
										<v-item v-slot="{ active, toggle }" value="mobile">
											<v-card
												:color="active ? activeColor : ''"
												min-height="100px"
												@click="toggle"
												outlined
											>
												<v-card-title>
													<v-icon>mdi-cellphone</v-icon>
													{{ $t('multi_factor_auth.activate.step1.list.mobile.title') }}
												</v-card-title>
												<v-card-text>{{
													$t('multi_factor_auth.activate.step1.list.mobile.text')
												}}</v-card-text>
											</v-card>
										</v-item>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-item v-slot="{ active, toggle }" value="mail">
											<v-card
												:color="active ? activeColor : ''"
												min-height="100px"
												@click="toggle"
												outlined
											>
												<v-card-title>
													<v-icon>mdi-email-outline</v-icon>
													{{ $t('multi_factor_auth.activate.step1.list.mail.title') }}
												</v-card-title>
												<v-card-text>{{
													$t('multi_factor_auth.activate.step1.list.mail.text')
												}}</v-card-text>
											</v-card>
										</v-item>
									</v-col>
								</v-row>
							</v-container>
						</v-item-group>
					</v-card-text>
					<v-card-actions class="justify-end">
						<v-btn color="secondary" min-width="100px" @click="$emit('setFlowType', '')">
							{{ $t('multi_factor_auth.cancel') }}
						</v-btn>
						<v-btn color="primary" min-width="100px" @click="setStep(2)" :disabled="!mfaType">
							{{ $t('multi_factor_auth.next') }}
						</v-btn>
					</v-card-actions>
				</v-stepper-content>

				<MobileStep
					v-if="isMfaApp"
					:activeColor="activeColor"
					:step="step"
					@setStep="setStep"
					@completed="$emit('completed')"
				/>
				<MailStep
					v-if="!isMfaApp"
					:step="step"
					@setStep="setStep"
					@completed="$emit('completed')"
				/>
			</v-stepper-items>
		</v-stepper>
	</v-card>
</template>

<script>
import MobileStep from './MobileStep.vue';
import MailStep from './MailStep.vue';

export default {
	name: 'EnableFlow',
	components: {
		MobileStep,
		MailStep
	},
	data: () => ({
		step: 1,
		mfaType: 'mobile'
	}),
	computed: {
		isMfaApp() {
			return this.mfaType === 'mobile';
		},
		activeColor() {
			return 'grey lighten-3';
		}
	},
	methods: {
		setStep(step) {
			this.step = step;
			this.$gtag.pageview(`${this.$route.path}?step=${step}`);
		}
	}
};
</script>
