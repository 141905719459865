<template>
	<v-autocomplete
		:items="items"
		@input="onInput"
		:value="value"
		:label="label"
		prepend-icon="mdi-earth"
		:item-text="selectedTimezoneValue"
		:validate-on-blur="validateOnBlur"
		:tabindex="tabindex"
		:readonly="readonly"
		:disabled="readonly"
		:clearable="clearable"
		:rules="rules"
	/>
</template>

<script>
import timezones from 'timezones-list';

export default {
	props: {
		value: {
			type: String,
			required: false,
			default: ''
		},
		label: {
			type: String,
			required: false,
			default: 'Timezone'
		},
		validateOnBlur: {
			type: Boolean,
			required: false,
			default: false
		},
		tabindex: {
			type: Number,
			required: false,
			default: 0
		},
		readonly: {
			type: Boolean,
			required: false,
			default: false
		},
		clearable: {
			type: Boolean,
			required: false,
			default: false
		},
		disableValidation: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => ({
		items: timezones
	}),
	computed: {
		selectedTimezoneValue() {
			return (item) => item.tzCode;
		},
		rules() {
			return [
				(v) => this.disableValidation || !!v || this.$t('errors.required', { key: this.label })
			];
		}
	},
	methods: {
		async onInput(zoneInfo) {
			this.$emit(`input`, zoneInfo);
		}
	}
};
</script>
