<template>
	<v-app>
		<NavigationHeader disabled-menu />
		<v-main class="grey lighten-5">
			<v-container>
				<v-row justify="space-around">
					<v-col align="center" cols="12" ms="8" md="8" lg="8" xl="8">
						<v-alert
							align="start"
							border="left"
							type="error"
							dense
							class="text-subtitle-2 ma-3"
							max-width="600px"
						>
							{{ $t(`errors.error_unknown`) }}
						</v-alert>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import NavigationHeader from '@/components/NavigationHeader.vue';

export default {
	name: 'UnexpectedError',
	components: {
		NavigationHeader
	}
};
</script>
