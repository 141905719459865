<template>
	<v-container>
		<v-row>
			<v-col cols="12" sm="8" md="8" lg="8" xl="8" class="pa-1 pa-sm-3 pr-sm-1">
				<v-card elevation="1" class="ma-sm-3 mr-sm-0">
					<v-stepper v-model="step" alt-labels>
						<v-stepper-items>
							<v-stepper-content step="1" class="py-1 px-0">
								<v-form v-model="valid" ref="form" @submit.prevent>
									<v-container class="pb-0">
										<v-row justify="space-between">
											<v-card-title>
												{{ $t('account_info.title') }}
											</v-card-title>
											<div class="pr-4">
												<v-checkbox
													v-model="editable"
													off-icon="mdi-lock"
													on-icon="mdi-lock-open-variant"
													:disabled="!!errorLabel"
												></v-checkbox>
											</div>
										</v-row>

										<v-alert
											border="left"
											type="error"
											dense
											dismissible
											close-label="alert"
											class="body-2"
											:value="!!errorLabel"
											@input="errorLabel = null"
										>
											<span v-if="errorLabel"> {{ $t(`errors.${errorLabel}`) }} </span>
										</v-alert>

										<v-row class="ma-0">
											<v-col cols="12" sm="6" md="6" xl="6" lg="6" class="py-0">
												<v-text-field
													:label="$t('account_info.account_type')"
													prepend-icon="mdi-account-cog"
													:value="user.accountType"
													readonly
													dence
												>
												</v-text-field>
											</v-col>
										</v-row>
									</v-container>

									<v-card-text class="ma-0 pa-2 py-0">
										<v-card elevation="0" class="ma-0 py-0">
											<v-card-title class="ma-0 pt-0">
												{{ $t('account_info.basicinfo_title') }}
											</v-card-title>
											<v-card-text>
												<v-row>
													<v-col cols="12">
														<NameInput
															v-model="nameValue"
															single-line
															input-middle-name
															@blur="$refs.form.validate()"
															:clearable="editable"
															:readonly="!editable"
															:disabled="!editable"
															:tabindex="1"
														/>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12" sm="6" md="6" xl="6" lg="6">
														<GenderSelector
															v-model="genderValue"
															:clearable="editable"
															:readonly="!editable"
															:disabled="!editable"
															disable-required-validation
															:tabindex="2"
														/>
													</v-col>
													<v-col cols="12" sm="6" md="6" xl="6" lg="6">
														<DatePicker
															v-model="user.birthdate"
															:label="$t('account_info.birthdate')"
															:clearable="editable"
															:disabled="!editable"
															:tabindex="3"
														/>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12">
														<TimezoneInput
															v-model="user.zoneinfo"
															:label="$t('account_info.zoneinfo')"
															:readonly="!editable"
															:disabled="!editable"
															clearable
															:tabindex="4"
														/>
													</v-col>
												</v-row>
											</v-card-text>
										</v-card>
										<v-card elevation="0" v-if="additionalInfoEnabled" class="ma-0">
											<v-card-title class="pt-0">
												{{ $t('account_info.additionalinfo_title') }}
											</v-card-title>
											<v-card-text>
												<v-row>
													<v-col cols="12" sm="6" md="6" xl="6" lg="6">
														<AdditionalNameInput
															v-model="user.preferredUsername"
															:label="$t('account_info.preferred_username')"
															:clearable="editable"
															:readonly="!editable"
															:disabled="!editable"
															disable-required-validation
															prepend-icon="mdi-account-star"
															:minlen="preferredUsernameMinlen"
															:maxlen="preferredUsernameMaxlen"
															:tabindex="5"
														/>
													</v-col>
													<v-col cols="12" sm="6" md="6" xl="6" lg="6">
														<AdditionalNameInput
															v-model="user.nickname"
															:label="$t('account_info.nickname')"
															:clearable="editable"
															:readonly="!editable"
															:disabled="!editable"
															disable-required-validation
															prepend-icon="mdi-account-music"
															:minlen="nicknameMinlen"
															:maxlen="nicknameMaxlen"
															:tabindex="6"
														/>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12">
														<LinkInput
															v-model="user.profile"
															:label="$t('account_info.profile')"
															:clearable="editable"
															:readonly="!editable"
															:disabled="!editable"
															disable-required-validation
															prepend-icon="mdi-link"
															:minlen="profileMinlen"
															:maxlen="profileMaxlen"
															:tabindex="7"
														/>
													</v-col>
													<v-col cols="12">
														<LinkInput
															v-model="user.website"
															:label="$t('account_info.website')"
															:clearable="editable"
															:readonly="!editable"
															:disabled="!editable"
															disable-required-validation
															prepend-icon="mdi-link"
															:minlen="websiteMinlen"
															:maxlen="websiteMaxlen"
															:tabindex="8"
														/>
													</v-col>
												</v-row>
											</v-card-text>
										</v-card>
										<v-card-actions>
											<v-spacer />
											<v-btn
												color="primary"
												@click="submit"
												:disabled="!valid"
												v-if="editable"
												min-width="100px"
											>
												{{ $t('account_info.submit') }}
											</v-btn>
										</v-card-actions>
									</v-card-text>
								</v-form>
							</v-stepper-content>
							<v-stepper-content step="2" class="pa-0">
								<v-card-title> {{ $t('account_info.success_title') }} </v-card-title>
								<v-card-text>
									{{ $t('account_info.msg.success') }}
								</v-card-text>
								<v-card-actions>
									<v-spacer />
									<v-btn color="secondary" @click="setStep(1)" min-width="100px">
										{{ $t('account_info.back') }}
									</v-btn>
								</v-card-actions>
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</v-card>
			</v-col>
			<v-col cols="12" md="4" lg="4" xl="4" class="pt-0 pa-1 pa-sm-3 pl-sm-1">
				<UploadNewAvator v-model="user.picture" class="mb-sm-2 ma-sm-3 ml-sm-0" />
				<v-card elevation="1" class="mt-1 mt-sm-0 ma-sm-3 ml-sm-0">
					<v-card-title>
						{{ $t('account_info.invitation_code') }}
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12" class="pt-0">
								<v-text-field
									:value="user.invitationCode"
									prepend-icon="mdi-email-newsletter"
									readonly
									persistent-hint
									:hint="$t('account_info.msg.invitation_code_pending')"
								>
								</v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import GenderSelector from '@/components/GenderSelector.vue';
import NameInput from '@/components/Input/NameInput.vue';
import AdditionalNameInput from '@/components/Input/AdditionalNameInput.vue';
import LinkInput from '@/components/Input/LinkInput.vue';
import UploadNewAvator from '@/components/UploadNewAvator.vue';
import TimezoneInput from '@/components/Input/TimezoneInput.vue';

export default {
	name: 'AccountInfo',
	data: () => ({
		user: {},
		errorLabel: null,
		valid: false,
		step: 1,
		editable: false,
		checkbox: false,
		preferredUsernameMaxlen: 128,
		preferredUsernameMinlen: 1,
		nicknameMaxlen: 32,
		nicknameMinlen: 1,
		profileMaxlen: 256,
		profileMinlen: 12,
		websiteMaxlen: 256,
		websiteMinlen: 12,
		additionalInfoEnabled: false
	}),
	components: {
		DatePicker,
		NameInput,
		GenderSelector,
		AdditionalNameInput,
		LinkInput,
		UploadNewAvator,
		TimezoneInput
	},
	computed: {
		nameValue: {
			get() {
				return {
					givenName: this.user.givenName,
					familyName: this.user.familyName,
					middleName: this.user.middleName,
					name: this.user.name
				};
			},
			set(value) {
				this.setObjectByKey(value);
			}
		},
		genderValue: {
			get() {
				return {
					gender: this.user.gender,
					genderCustomText: this.user.genderCustomText
				};
			},
			set(value) {
				this.setObjectByKey(value);
			}
		}
	},
	async created() {
		await this.init();
	},
	methods: {
		async init() {
			try {
				const { data: user } = await this.$axios.oidc.get(`/api/v1/user`);
				this.user = user;
			} catch (e) {
				this.errorLabel = 'unknown';
			}
		},
		async submit() {
			try {
				const {
					email,
					emailVerified,
					phoneNumberVerified,
					mfaEnabled,
					mfaAppEnabled,
					updatedAt,
					createdAt,
					accountId,
					invitationCode,
					accountType,
					lastLoginAt,
					...user
				} = this.user;
				await this.$axios.oidc.patch(`/api/v1/user`, user);
				this.setStep(2);
			} catch (e) {
				switch (e.response.status) {
					case 400:
						this.$refs.form.resetValidation();
						this.errorLabel = 'invalid_value';
						break;
					case 403:
						this.$refs.form.resetValidation();
						this.errorLabel = 'invalid_value';
						break;
					default:
						this.errorLabel = 'unknown';
						break;
				}
			}
		},
		setObjectByKey(value) {
			Object.keys(value).forEach((key) => {
				if (key in this.user) this.user[key] = value[key];
			});
		},
		setStep(step) {
			this.step = step;
			if (step === 1) this.editable = false;

			this.$gtag.pageview(`${this.$route.path}?step=${step}`);
		}
	}
};
</script>
