<template>
	<v-col :class="className">
		<PincodeInput
			:value="token"
			@input="$emit('input', $event)"
			:length="6"
			:secure="false"
			placeholder="0"
		/>
	</v-col>
</template>

<script>
import PincodeInput from 'vue-pincode-input';

export default {
	name: 'CodeInput',
	model: {
		prop: `token`
	},
	components: {
		PincodeInput
	},
	props: {
		token: {
			type: String,
			default: '',
			required: true
		}
	},
	computed: {
		className() {
			return this.$vuetify.breakpoint.name === 'xs' ? 'text-center' : 'text-center ma-10';
		}
	}
};
</script>
