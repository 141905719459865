<template>
	<div id="navigation">
		<v-app-bar app color="white" dark clipped-left :class="[environment]">
			<v-app-bar-nav-icon light @click.stop="drawer = !drawer" />
			<router-link class="d-flex align-center" to="/">
				<v-img
					:src="require('@/assets/logo_head.svg')"
					class="my-3"
					contain
					height="40"
					:width="imgWidth"
				/>
			</router-link>
			<v-toolbar-title class="grey--text text--darken-2 d-none d-sm-block">
				{{ $t('app.title') }}
			</v-toolbar-title>

			<v-spacer />

			<div v-if="!isMobile">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							href="https://www.shiftinc.jp/"
							target="_blank"
							v-bind="attrs"
							v-on="on"
							icon
							style="width: 24px"
							class="mx-2"
						>
							<v-icon>mdi-dark mdi-open-in-new</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('menus.HPlink.tooltip') }}</span>
				</v-tooltip>
				<v-menu bottom left transition="slide-y-transition" open-on-hover offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on" style="width: 24px" class="mx-2">
							<v-icon>mdi-dark mdi-earth</v-icon>
						</v-btn>
					</template>

					<v-list>
						<v-subheader>{{ $t('app.language') }}</v-subheader>
						<v-list-item-group>
							<v-list-item v-for="(language, i) in languages" :key="i">
								<v-list-item-content @click="changeLocale(language.locale)">
									<v-list-item-title>{{ language.label }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-menu>
			</div>
			<NotificationBell v-if="!disabledMenu" class="mx-2" />
			<v-menu
				v-if="!disabledReader"
				transition="slide-y-transition"
				offset-y
				:close-on-content-click="false"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" style="width: 24px" class="mx-2">
						<v-icon>mdi-dark mdi-dots-vertical</v-icon>
					</v-btn>
				</template>

				<v-list>
					<div v-if="isMobile">
						<v-list-item href="https://www.shiftinc.jp/">
							<v-list-item-icon>
								<v-icon>mdi-dark mdi-open-in-new</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ $t('menus.HPlink.tooltip') }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item @click.stop="selectLanguage = !selectLanguage">
							<v-list-item-icon>
								<v-icon>mdi-dark mdi-earth</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ $t('app.language') }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</div>
					<v-list-item @click.stop="logout">
						<v-list-item-icon>
							<v-icon>mdi-logout-variant</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>{{ $t('point_reader.logout.menu') }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>
		<v-navigation-drawer v-if="!disabledMenu" v-model="drawer" color="white" app clipped bottom>
			<v-list dense>
				<v-list-item-group active-class="list-item" v-model="selected" max="1" color="red">
					<v-list-item
						link
						class="list-item"
						v-for="menu in displayMenus"
						:key="menu.route"
						:to="menu.route"
						exact
					>
						<v-list-item-action>
							<v-icon>{{ menu.icon }}</v-icon>
						</v-list-item-action>
						<v-tooltip bottom open-delay="1250">
							<template v-slot:activator="{ on, attrs }">
								<v-list-item-content v-bind="attrs" v-on="on">
									<v-list-item-title>{{ $t(menu.title) }}</v-list-item-title>
								</v-list-item-content>
							</template>
							<span>{{ $t(menu.tooltip) }}</span>
						</v-tooltip>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
		<v-navigation-drawer
			v-if="!disabledMenu"
			v-model="selectLanguage"
			color="white"
			app
			bottom
			right
			temporary
		>
			<v-list>
				<v-subheader>{{ $t('app.language') }}</v-subheader>
				<v-list-item-group>
					<v-list-item v-for="(language, i) in languages" :key="i">
						<v-list-item-content @click="changeLocale(language.locale)">
							<v-list-item-title>{{ language.label }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>
<script>
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';

import envIdentify from '@/lib/env-identify';
import NotificationBell from '@/components/NotificationBell.vue';

export default {
	props: {
		belongToOrganization: {
			type: Boolean,
			default: false,
			required: false
		},
		disabledMenu: {
			type: Boolean,
			default: false,
			required: false
		},
		disabledReader: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data: () => ({
		environment: 'env-production',
		drawer: true,
		selected: null,
		languages: [
			{
				locale: 'ja',
				label: '日本語'
			},
			{
				locale: 'en',
				label: 'English'
			}
		],
		displayMenus: [],
		selectLanguage: false,
		baseMenus: [
			{
				route: '/',
				icon: 'mdi-account-edit',
				title: 'menus.account_info.title',
				tooltip: 'menus.account_info.tooltip',
				order: 1
			},
			{
				route: '/email',
				icon: 'mdi-email-edit',
				title: 'menus.change_email.title',
				tooltip: 'menus.change_email.tooltip',
				order: 2
			},
			{
				route: '/password',
				icon: 'mdi-lock',
				title: 'menus.change_password.title',
				tooltip: 'menus.change_password.tooltip',
				order: 4
			},
			{
				route: '/mfa',
				icon: 'mdi-cellphone',
				title: 'menus.multi_factor_auth.title',
				tooltip: 'menus.multi_factor_auth.tooltip',
				order: 5
			},
			{
				route: '/invite/organizations',
				icon: 'mdi-account-multiple-plus',
				title: 'menus.organization_invited.title',
				tooltip: 'menus.organization_invited.tooltip',
				order: 6
			}
		],
		customMenus: [
			{
				route: '/email/recovery',
				icon: 'mdi-email',
				title: 'menus.recovery_email.title',
				tooltip: 'menus.recovery_email.tooltip',
				order: 3,
				belongToOrganizationDisplay: false
			},
			{
				route: '/organizations',
				icon: 'mdi-city',
				title: 'menus.organizations.title',
				tooltip: 'menus.organizations.tooltip',
				order: 7,
				belongToOrganizationDisplay: true
			},
			{
				route: '/delete',
				icon: 'mdi-account-remove',
				title: 'menus.delete_account.title',
				tooltip: 'menus.delete_account.tooltip',
				order: 8,
				belongToOrganizationDisplay: false
			}
		]
	}),
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.xs;
		},
		imgWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 180;
				default:
					return 220;
			}
		}
	},
	components: {
		NotificationBell
	},
	watch: {
		belongToOrganization() {
			this.init();
		}
	},
	created() {
		this.init();
		this.environment = envIdentify();
	},
	methods: {
		init() {
			this.displayMenus.length = 0;
			this.displayMenus = cloneDeep(this.baseMenus);

			this.customMenus
				.filter(
					(menu) =>
						(menu.belongToOrganizationDisplay || !this.belongToOrganization) &&
						(!menu.belongToOrganizationDisplay || this.belongToOrganization)
				)
				.forEach((menu) => {
					this.displayMenus.push(menu);
				});
			this.displayMenus.sort((a, b) => a.order - b.order);

			this.displayMenus.forEach((menu, index) => {
				if (menu.route === this.$route.path) this.selected = index;
			});
		},
		changeLocale(locale) {
			this.$i18n.locale = locale;
			this.$vuetify.lang.current = locale;
			switch (locale) {
				case 'en':
					document.getElementsByTagName('html').item(0).lang = 'en';
					break;
				default:
					document.getElementsByTagName('html').item(0).lang = 'ja';
					break;
			}
			this.selectLanguage = false;
		},
		async logout() {
			const {
				data: { logoutUrl }
			} = await axios.get(`/logout/`, { params: { ui_locales: this.$i18n.locale } });

			window.open(logoutUrl, '_self');
		}
	}
};
</script>
<style lang="sass" scoped>
#navigation::v-deep
	.list-item
		border-left: solid 3px transparent
		&.v-list-item--active
			border-left: solid 3px #D70027
	.red_list .v-list-item-group .v-list-item--active
		background-color: red
		color: white

.env-template
	&::after
		text-align: center
		position: absolute
		border-radius: 0 0 5px 5px
		height: 16px
		left: calc(50% - 50px)
		color: white
		font-size: 6px
		width: 100px

header.env-local
		border-bottom: 3px solid rgba(128, 128, 128, 0.8) !important
		&::after
			@extend .env-template
			content: 'Local Development'
			background-color:  rgba(128, 128, 128, 0.8)
header.env-dev
		border-bottom: 3px solid rgba(69, 90, 100, 0.8) !important
		&::after
			@extend .env-template
			content: 'AWS Development'
			background-color:  rgba(69, 90, 100, 0.8)
header.env-sandbox
		border-bottom: 3px solid rgba(255, 160, 0, 0.8) !important
		&::after
			@extend .env-template
			content: 'Sandbox'
			background-color:  rgba(255, 160, 0, 0.8)
header.env-green
		border-bottom: 3px solid rgb(46, 125, 50, 0.8) !important
		&::after
			@extend .env-template
			content: 'Production/Green'
			background-color:  rgba(46, 125, 50, 0.8)
</style>
