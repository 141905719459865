<template>
	<div>
		<v-footer app padless>
			<v-row justify="center" no-gutters class="secondary lighten-2 py-2">
				<!-- eslint-disable-next-line vue/no-template-target-blank -->
				<a target="_blank" href="https://www.shiftinc.jp/">{{ $t('footer.investment_company') }}</a>
				<v-divider vertical class="white mx-2" />
				<!-- eslint-disable-next-line vue/no-template-target-blank -->
				<a target="_blank" href="https://www.catcloud.net/contact.html">{{
					$t('footer.contact')
				}}</a>
				<v-divider vertical class="white mx-2" />
				<!-- eslint-disable-next-line vue/no-template-target-blank -->
				<a target="_blank" href="https://www.shiftinc.jp/isms/">{{
					$t('footer.information_security_policy')
				}}</a>
				<v-divider vertical class="white mx-2" />
				<!-- eslint-disable-next-line vue/no-template-target-blank -->
				<a target="_blank" href="https://www.shiftinc.jp/privacy/">{{
					$t('footer.privacy_policy')
				}}</a>
				<v-divider vertical class="white mx-2" />
				<!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
				<a @click.stop.prevent="openDialog">{{ $t('footer.service_agreement') }} </a>
				<v-col cols="12" class="white--text text-center caption pt-2">
					<strong>{{ $t('footer.copy_wright') }}</strong>
				</v-col>
			</v-row>
		</v-footer>
		<v-dialog v-model="dialog" fullscreen>
			<v-card>
				<v-card-text>
					<v-container class="overflow-y-auto">
						<v-alert
							border="left"
							type="error"
							dense
							dismissible
							close-label="alert"
							class="body-2"
							:value="!!errorLabel"
							@input="errorLabel = null"
						>
							<span> {{ $t(`errors.${errorLabel}`) }} </span>
						</v-alert>
						<v-row v-if="!errorLabel">
							<div class="text-h5 text--primary my-4">
								{{ $t('footer.service_agreement') }} {{ tosTitle }}
							</div>
							<div v-html="tosText" />
							<div>{{ dateFormat }}</div>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-btn fixed bottom right color="primary" @click="dialog = false">
						{{ $t('footer.dialog.close') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { marked } from 'marked';
import { DateTime } from 'luxon';

export default {
	name: 'NavigationFooter',
	components: {},
	data: () => ({
		dialog: false,
		tosTitle: '',
		tosRevisionOn: '',
		tosBody: '',
		errorLabel: null
	}),
	watch: {
		'$i18n.locale': {
			immediate: true,
			async handler() {
				await this.init();
			}
		}
	},
	computed: {
		dateFormat() {
			return DateTime.fromFormat(this.tosRevisionOn, 'yyyy-MM-dd')
				.setLocale(this.$i18n.locale)
				.toLocaleString(DateTime.DATE_FULL);
		},
		tosText() {
			return marked(this.tosBody);
		}
	},
	methods: {
		async init() {
			this.errorLabel = null;

			try {
				const {
					data: { title, revisionOn, body }
				} = await this.$axios.oidc.get(`/api/v2/private/tos/jp/${this.$i18n.locale}`);
				this.tosTitle = title;
				this.tosRevisionOn = revisionOn;
				this.tosBody = body;
			} catch (e) {
				this.errorLabel = 'do_not_get_service_agreement';
			}
		},
		openDialog() {
			this.dialog = true;
		}
	}
};
</script>
<style lang="sass" scoped>
.v-footer
	a
		color: white
		text-decoration: none
		font-size: 12px
	a:hover
		text-decoration: underline
</style>
