import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import Store from '@/store/';
import customAxios from '@/plugins/custom-axios';
import qs from 'qs';

import Home from '@/pages/views/Home.vue';
import EmailChange from '@/pages/views/EmailChange.vue';
import EmailVerify from '@/pages/views/EmailVerify.vue';
import TwofactorAuth from '@/pages/views/TwofactorAuth.vue';
import RecoveryEmail from '@/pages/views/RecoveryEmail.vue';
import RecoveryEmailVerify from '@/pages/views/RecoveryEmailVerify.vue';
import AccountInfo from '@/pages/views/AccountInfo.vue';
import InvitedOrganization from '@/pages/views/InvitedOrganization.vue';
import BelongedOrganization from '@/pages/views/BelongedOrganization.vue';
import LeaveAccount from '@/pages/views/LeaveAccount.vue';
import UnexpectedError from '@/pages/views/UnexpectedError.vue';
import NotLogined from '@/pages/views/NotLogined.vue';
import envIdentify from '@/lib/env-identify';
import VueGtag from 'vue-gtag';
import PasswordView from '@/pages/views/PasswordView.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/login',
		// eslint-disable-next-line no-unused-vars
		redirect: (to) => {
			const { url } = to.query;
			window.location.href = url ? `/login/?${qs.stringify({ url })}` : '/login/';
		}
	},
	{
		path: '/error',
		component: UnexpectedError
	},
	{
		path: '/notlogin',
		component: NotLogined
	},
	{
		path: '/',
		meta: { requiresAuth: true },
		component: Home,
		children: [
			{
				path: '/',
				component: AccountInfo
			},
			{
				path: 'email',
				component: EmailChange
			},
			{
				path: 'email/verify/:code',
				component: EmailVerify
			},
			{
				path: 'password',
				component: PasswordView
			},
			{
				path: 'mfa',
				component: TwofactorAuth
			},
			{
				path: 'email/recovery',
				component: RecoveryEmail,
				meta: { belongToOrganizationDisplay: false }
			},
			{
				path: 'email/recovery/verify/:code',
				component: RecoveryEmailVerify,
				meta: { belongToOrganizationDisplay: false }
			},
			{
				path: 'invite/organizations',
				component: InvitedOrganization
			},
			{
				path: 'organizations',
				component: BelongedOrganization,
				meta: { belongToOrganizationDisplay: true }
			},
			{
				path: 'delete',
				component: LeaveAccount,
				meta: { belongToOrganizationDisplay: false }
			},
			{
				path: '*',
				beforeEnter: (to, from, next) => {
					next('/');
				}
			}
		]
	}
];

const router = new VueRouter({
	mode: 'history',
	routes
});

router.beforeEach(async (to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		try {
			const {
				data: { token: accountToken }
			} = await axios.get(`/login/token`);

			Store.dispatch('setAccountToken', accountToken);
			Vue.prototype.$axios = customAxios(accountToken);

			try {
				const {
					data: { total }
				} = await Vue.prototype.$axios.oidc.get(`/api/v1/organizations`, {
					params: {
						limit: 1
					}
				});
				Store.dispatch('setBelongToOrganization', !!total);
			} catch (error) {
				next('/error');
			}
		} catch (e) {
			next({ path: '/login', query: { url: to.path } });
		}
	}

	if (
		Store.state.belongToOrganization &&
		to.matched.some((record) => record.meta.belongToOrganizationDisplay === false)
	)
		next('/');

	if (
		!Store.state.belongToOrganization &&
		to.matched.some((record) => record.meta.belongToOrganizationDisplay)
	)
		next('/');

	next();
});

Vue.use(
	VueGtag,
	{
		config: { id: envIdentify() === 'env-production' ? 'G-2YKLT3K3RE' : 'UA-DUMY123-1' }
	},
	router
);

export default router;
