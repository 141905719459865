<template>
	<div>
		<v-dialog v-model="dialog.register" persistent max-width="600px">
			<v-card>
				<v-card-title>{{ $t(`plofile_picture.dialog.register_title`) }}</v-card-title>
				<v-card-text>
					<v-alert
						border="left"
						type="error"
						dense
						dismissible
						close-label="alert-register"
						class="body-2"
						:value="!!errorLabel.register"
						@input="errorLabel.register = null"
					>
						<span v-if="errorLabel.register"> {{ $t(`errors.${errorLabel.register}`) }} </span>
					</v-alert>

					<v-row v-show="enabled" align="center">
						<v-col cols="8" align="center">
							<div style="width: 100%">
								<img alt="cropper" ref="cropper" :src="img.origin" @load.stop="createCropper" />
							</div>
						</v-col>
						<v-col cols="4" align="center">
							<v-row>
								<v-col v-if="square">
									<v-avatar :size="size" tile>
										<v-img :src="img.cropped"></v-img>
									</v-avatar>
								</v-col>
								<v-col v-else>
									<v-avatar :size="size">
										<v-img :src="img.cropped"></v-img>
									</v-avatar>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="secondary" @click="dialog.register = false" min-width="100px">
						{{ $t('plofile_picture.cancel_label') }}
					</v-btn>
					<v-btn :loading="loading" color="primary" @click="save" min-width="100px">
						{{ $t(`plofile_picture.save_label`) }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialog.delete" persistent max-width="600px">
			<v-card>
				<v-card-title> {{ $t('plofile_picture.dialog.delete_title') }} </v-card-title>
				<v-card-text>
					<v-alert
						border="left"
						type="error"
						dense
						dismissible
						close-label="alert-delete"
						class="body-2"
						:value="!!errorLabel.delete"
						@input="errorLabel.delete = null"
					>
						<span v-if="errorLabel.delete"> {{ $t(`errors.${errorLabel.delete}`) }} </span>
					</v-alert>

					<v-row>
						<v-col cols="12" align="center" v-if="picture">
							<v-avatar :size="avatorSize">
								<img alt="picture" :src="picture" />
							</v-avatar>
						</v-col>
						<v-col>{{ $t('plofile_picture.dialog.confirm_delete_msg') }}</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="secondary" @click="dialog.delete = false" min-width="100px">
						{{ $t('plofile_picture.cancel_label') }}
					</v-btn>
					<v-btn color="error" @click="deleteImg" min-width="100px">
						{{ $t('plofile_picture.dialog.delete') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-card elevation="1">
			<v-card-title>{{ $t('plofile_picture.title') }}</v-card-title>
			<v-card-text>
				<v-alert
					border="left"
					type="error"
					dense
					dismissible
					close-label="alert-select"
					class="body-2"
					:value="!!errorLabel.select"
					@input="errorLabel.select = null"
				>
					<span v-if="errorLabel.select"> {{ $t(`errors.${errorLabel.select}`) }} </span>
				</v-alert>

				<!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
				<input
					:value="img.input"
					ref="selecter"
					class="d-none"
					type="file"
					accept="image/png, image/jpeg, image/gif"
					@input.stop="onFileInput"
				/>

				<v-row>
					<v-col cols="12" align="center">
						<ProfileImage :picture="picture" />
					</v-col>
				</v-row>

				<v-row justify="center">
					<v-col cols="2" align="center" class="pt-0">
						<v-btn @click="onClickEditIcon" icon>
							<v-icon> mdi-pencil </v-icon>
						</v-btn>
					</v-col>
					<v-col cols="2" align="center" class="pt-0">
						<v-btn :disabled="!picture" @click="onClickDelIcon" icon>
							<v-icon> mdi-delete </v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import 'cropperjs/dist/cropper.min.css';
import Cropper from 'cropperjs';
import crypto from 'crypto';

import ProfileImage from '@/components/ProfileImage.vue';

export default {
	name: 'UploadNewAvator',
	model: {
		prop: `picture`
	},
	props: {
		picture: {
			type: String,
			default: ''
		},
		maxImgSize: {
			type: Number,
			default: 1024 * 1024 * 1
		},
		avatorSize: {
			type: Number,
			default: 120
		},
		square: {
			type: Boolean,
			default: false
		}
	},
	components: {
		ProfileImage
	},
	data: () => ({
		img: {
			input: null,
			origin: null,
			cropped: null
		},
		cropper: null,
		dialog: {
			register: false,
			delete: false
		},
		enabled: false,
		loading: false,
		errorLabel: {
			select: null,
			register: null,
			delete: null
		}
	}),
	computed: {
		size() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 80;
				default:
					return 125;
			}
		},
		minHeightWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 200;
				default:
					return 300;
			}
		}
	},
	methods: {
		async save() {
			this.init();
			this.loading = true;

			const image = this.img.cropped.split(',', 2)[1];
			try {
				const { data: file } = await this.$axios.storage.post(`/api/v1/file/storage/public/image`, {
					...this.getMimeBase64ContType(this.img.cropped),
					image,
					md5hex: this.getMd5hex(image)
				});
				const { data: user } = await this.$axios.oidc.patch(`/api/v1/user`, {
					picture: file.url
				});

				if (this.picture && this.picture !== user.picture)
					await this.$axios.storage.delete(`/api/v1/file/storage/public/image`, {
						data: { url: this.picture }
					});

				this.$emit('input', user.picture);
				this.dialog.register = false;
			} catch (e) {
				this.errorLabel.register = 'unknown';
			} finally {
				this.loading = false;
			}
		},
		async deleteImg() {
			this.init();

			try {
				const { data: user } = await this.$axios.oidc.patch(`/api/v1/user`, {
					picture: null
				});

				await this.$axios.storage.delete(`/api/v1/file/storage/public/image`, {
					data: { url: this.picture }
				});

				this.$emit('input', user.picture);
				this.dialog.delete = false;
			} catch (e) {
				this.errorLabel.delete = 'unknown';
			}
		},
		onClickEditIcon() {
			this.init();
			this.reset();
			this.$refs.selecter.click();
		},
		onClickDelIcon() {
			this.dialog.delete = true;
			this.errorLabel.delete = null;
		},
		async onFileInput(e) {
			const selectedImg = e.target.files[0];
			if (this.cropper) this.cropper.destroy();

			const reader = new FileReader();
			reader.onloadend = (rd) => {
				this.img.origin = rd.target.result;
				if (!this.getMimeBase64ContType(this.img.origin).contentType) {
					this.errorLabel.select = 'unsupported_extension';
					return;
				}
				if (selectedImg.size >= this.maxImgSize) {
					this.errorLabel.select = 'over_size';
					return;
				}
				this.dialog.register = true;
			};
			reader.readAsDataURL(selectedImg);
		},
		createCropper() {
			this.cropper = new Cropper(this.$refs.cropper, {
				viewMode: 1,
				aspectRatio: 1,
				autoCropArea: 1,
				movable: false,
				zoomable: false,
				responsive: false,
				minContainerHeight: this.minHeightWidth,
				minContainerWidth: this.minHeightWidth,
				ready: () => {
					this.img.cropped = this.cropper.getCroppedCanvas().toDataURL();
					this.enabled = true;
				},
				cropend: () => {
					this.img.cropped = this.cropper.getCroppedCanvas().toDataURL();
				}
			});
		},
		init() {
			this.errorLabel = {
				select: null,
				register: null,
				delete: null
			};
		},
		reset() {
			this.enabled = false;
			this.img.origin = null;
		},
		getMimeBase64ContType(base64file) {
			const file = base64file.substring(0, 128).split(',', 2)[1];
			switch (file.toUpperCase().substring(0, 5)) {
				case 'IVBOR':
					return { contentType: 'image/png' };
				case '/9J/4':
					return { contentType: 'image/jpeg' };
				case 'R0LGO':
					return { contentType: 'image/gif' };
				default:
					return { contentType: '' };
			}
		},
		getMd5hex(image) {
			const buffer = Buffer.from(image, 'base64');
			const md5hash = crypto.createHash('md5');
			md5hash.update(buffer);
			return md5hash.digest('hex');
		}
	}
};
</script>
