import axios from 'axios';
import { strict as assert } from 'assert';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import axiosRetry, { exponentialDelay } from 'axios-retry';

export default (token) => {
	const proxyGroups = [`oidc`, `storage`, `notification`];
	const schemas = {};

	assert.ok(token);

	proxyGroups.forEach((proxyGroup) => {
		schemas[proxyGroup] = axios.create({
			baseURL: `/proxy/${proxyGroup}`,
			headers: {
				common: {
					Authorization: `Bearer ${token}`
				}
			}
		});
		schemas[proxyGroup].interceptors.request.use(
			(request) => {
				const { data, params } = request;
				if (params)
					return {
						...request,
						params: snakecaseKeys(params)
					};
				if (!data) return request;

				return {
					...request,
					data: typeof data === 'string' ? data : snakecaseKeys(data, { deep: true })
				};
			},
			(e) => Promise.reject(e)
		);
		schemas[proxyGroup].interceptors.response.use(
			(response) => {
				const { data } = response;
				if (!data) return response;

				return {
					...response,
					data: camelcaseKeys(response.data, { deep: true })
				};
			},
			(e) => Promise.reject(e)
		);
		// 今後4XX 系も追加する場合、サーバーへの負荷関連429を除く
		axiosRetry(schemas[proxyGroup], {
			retryCondition: (error) =>
				error.code !== 'ECONNABORTED' &&
				(!error.response || (error.response.status >= 500 && error.response.status !== 503)),
			retryDelay: exponentialDelay
		});
	});

	return schemas;
};
