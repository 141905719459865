<template>
	<v-text-field
		:value="value"
		@input="onInput"
		:rules="rules"
		:label="label"
		:required="required"
		:prepend-icon="prependIcon"
		persistent-hint
		:validate-on-blur="validateOnBlur"
		:counter="readonly ? null : maxlen"
		:clearable="clearable"
		:tabindex="tabindex"
		:readonly="readonly"
		:disabled="disabled"
	/>
</template>
<script>
export default {
	props: {
		value: {
			type: String,
			required: false,
			default: ''
		},
		validateOnBlur: {
			type: Boolean,
			required: false,
			default: false
		},
		label: {
			type: String,
			required: false,
			default: 'userinfo'
		},
		required: {
			type: Boolean,
			required: false,
			default: false
		},
		clearable: {
			type: Boolean,
			required: false,
			default: false
		},
		hint: {
			type: String,
			required: false,
			default: ''
		},
		disableRequiredValidation: {
			type: Boolean,
			required: false,
			default: false
		},
		tabindex: {
			type: Number,
			required: false,
			default: 0
		},
		prependIcon: {
			type: String,
			required: false,
			default: 'mdi-lock'
		},
		minlen: {
			type: Number,
			required: false,
			default: 8
		},
		maxlen: {
			type: Number,
			required: false,
			default: 32
		},
		readonly: {
			type: Boolean,
			required: false,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		rules() {
			return [
				(v) =>
					this.disableRequiredValidation || !!v || this.$t('errors.required', { key: this.label }),
				// ０の場合にvalidationが通ってしまうので要注意
				(v) =>
					!v ||
					v.length >= this.minlen ||
					this.$t('errors.minlen', { key: this.label, minlen: this.minlen }),
				(v) =>
					!v ||
					v.length <= this.maxlen ||
					this.$t('errors.maxlen', { key: this.label, maxlen: this.maxlen })
			];
		}
	},
	methods: {
		onInput(value) {
			this.$emit(`input`, value);
		}
	}
};
</script>
