<template>
	<v-container>
		<v-row>
			<v-col cols="12" ms="8" md="8" lg="8" xl="8" class="pa-1 pa-sm-3 pr-sm-1">
				<v-card elevation="1" class="pt-6 ma-sm-3 mr-sm-0">
					<v-row>
						<v-col cols="12" ms="4" md="4" lg="4" xl="4" class="mt-n6">
							<v-card-title> {{ $t('organization_belonged.title_organizations') }} </v-card-title>
						</v-col>
						<v-col cols="12" ms="8" md="8" lg="8" xl="8">
							<v-btn
								absolute
								top
								right
								dark
								elevation="2"
								color="primary lighten-1"
								:style="{ top: '12px', right: '16px' }"
								@click="navigateToNewOrganization"
							>
								<v-icon dark left size="24px"> mdi-plus </v-icon>
								{{ $t('organization_belonged.card.add_organization') }}
							</v-btn>
						</v-col>
					</v-row>
					<v-card-text>
						<v-alert
							border="left"
							type="error"
							dense
							dismissible
							close-label="alert"
							class="body-2"
							:value="!!errorLabel"
							@input="errorLabel = null"
						>
							<span v-if="errorLabel"> {{ $t(`errors.${errorLabel}`) }} </span>
						</v-alert>

						<v-data-table
							:headers="headers"
							:items="organizations"
							:server-items-length="total"
							:footer-props="footerProps"
							:no-data-text="$t('organization_belonged.table.no_data_text')"
							:no-results-text="$t('organization_belonged.table.no_result_text')"
							:mobile-breakpoint="960"
							@click:row="navigateToOrganizationManager"
						>
							<template #[`item.name`]="{ item }">
								<v-avatar :size="30" class="mr-2">
									<v-img v-if="item.logo" :src="item.logo" contain />
									<v-icon v-else> mdi-dark mdi-domain </v-icon>
								</v-avatar>
								<span>{{ item.name }}</span>
							</template>

							<template #[`item.department`]="{ item }">
								<span>{{ item.department }}</span>
							</template>

							<template #[`item.privilege`]="{ item }">
								<v-chip :color="chipColor(item.isPrivileged)" small outlined>
									{{ $t(chipI18nId(item.isPrivileged)) }}
								</v-chip>
							</template>

							<template #[`item.createdAt`]="{ item }">
								{{ formatedDate(item.createdAt) }}
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from 'axios';
import { DateTime } from 'luxon';

export default {
	name: 'BelongedOrganization',
	data: () => ({
		total: 0,
		organizations: [],
		errorLabel: null,
		counter: {
			incrementPerSeconds: 60,
			value: 0,
			intervalId: null
		},
		footerProps: {
			itemsPerPageOptions: [10, 50, 100]
		}
	}),
	async created() {
		this.setIntervalIncrementCounter();
		await this.init();
	},
	destroyed() {
		clearInterval(this.counter.intervalId);
	},
	methods: {
		async init() {
			try {
				await this.fetchOrganizations();
			} catch (e) {
				this.errorLabel = 'unknown';
			}
		},
		async fetchOrganizations() {
			const {
				data: { total, organizations }
			} = await this.$axios.oidc.get('/api/v1/organizations');
			this.total = total;
			this.organizations = organizations;
		},
		async navigateToNewOrganization() {
			const {
				data: { origin }
			} = await axios.get(`/origin/organization`);

			window.open(`${origin}/new`, '_blank');
		},
		async navigateToOrganizationManager(organization) {
			const {
				data: { origin }
			} = await axios.get(`/origin/organization`);

			window.open(`${origin}/info/${organization.id}`, '_blank');
		},
		setIntervalIncrementCounter() {
			this.counter.intervalId = setInterval(() => {
				this.counter.value += 1;
			}, this.counter.incrementPerSeconds * 1000);
		}
	},
	computed: {
		headers() {
			return [
				{
					text: this.$t('organization_belonged.table.header.organization_name'),
					sortable: false,
					value: 'name'
				},
				{
					text: this.$t('organization_belonged.table.header.department'),
					sortable: false,
					value: 'department'
				},
				{
					text: this.$t('organization_belonged.table.header.privilege'),
					align: 'center',
					sortable: false,
					value: 'privilege'
				},
				{
					text: this.$t('organization_belonged.table.header.created_at'),
					align: 'center',
					sortable: false,
					value: 'createdAt'
				}
			];
		},
		chipColor() {
			return (isPrivileged) => {
				if (isPrivileged) return 'primary';
				return 'error';
			};
		},
		chipI18nId() {
			return (isPrivileged) => {
				if (isPrivileged) return 'organization_belonged.table.body.privilege';
				return 'organization_belonged.table.body.member';
			};
		},
		formatedDate() {
			// eslint-disable-next-line no-unused-expressions
			this.counter.value;
			return (createdAt) => {
				const luxonDate = DateTime.fromSeconds(createdAt);
				if (DateTime.local().diff(luxonDate, 'seconds').seconds < 60)
					return `< ${luxonDate.setLocale(this.$i18n.locale).toRelative()}`;
				return luxonDate.setLocale(this.$i18n.locale).toRelative();
			};
		}
	}
};
</script>
