<template>
	<v-container>
		<v-row>
			<v-col cols="12" md="8" lg="8" xl="8" class="pa-1 pa-sm-3 pr-sm-1">
				<v-card elevation="1" class="ma-sm-3 mr-sm-0">
					<v-stepper v-model="step" alt-labels>
						<v-stepper-items>
							<v-stepper-content step="1" class="pa-0">
								<v-form v-model="valid" ref="form" @submit.prevent>
									<v-card-title> {{ $t('change_password.title') }} </v-card-title>
									<v-card-text>
										<v-alert
											border="left"
											type="error"
											dense
											dismissible
											close-label="alert"
											class="body-2"
											:value="!!error"
											@input="error = null"
										>
											<span v-if="error"> {{ $t(`errors.${error}`) }} </span>
										</v-alert>
										<v-col md="12">
											<PasswordInput
												v-model="currentPassword"
												:label="$t('change_password.current_password')"
												@blur="$refs.form.validate()"
												validator
												clearable
												:tabindex="1"
												:hint="$t('change_password.current_password_hint')"
											/>
										</v-col>
										<v-col md="12">
											<PasswordInput
												v-model="newPassword"
												:label="$t('change_password.new_password')"
												@blur="$refs.form.validate()"
												validator
												clearable
												strength-meter
												:tabindex="2"
												:hint="$t('change_password.new_password_hint')"
											/>
										</v-col>
									</v-card-text>
									<v-card-actions>
										<v-spacer />
										<v-btn color="primary" @click="submit" :disabled="!valid" min-width="100px">
											{{ $t('change_password.submit') }}
										</v-btn>
									</v-card-actions>
								</v-form>
							</v-stepper-content>
							<v-stepper-content step="2" class="pa-0">
								<v-card-title> {{ $t('change_password.title') }} </v-card-title>
								<v-card-text>
									{{ $t('change_password.changed') }}
								</v-card-text>
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import PasswordInput from '@/components/Input/PasswordInput.vue';

export default {
	name: 'PasswordView',
	components: {
		PasswordInput
	},
	data: () => ({
		valid: false,
		step: 1,
		error: null,
		currentPassword: '',
		newPassword: ''
	}),
	methods: {
		async submit() {
			try {
				await this.$axios.oidc.put(`/api/v1/user/password`, {
					password: this.currentPassword,
					newPassword: this.newPassword
				});
				this.setStep(2);
			} catch (e) {
				this.error = 'unknown';
				switch (e.response?.status) {
					case 403:
						this.$refs.form.resetValidation();
						this.error = 'invalid_password';
						break;
					case 409:
						this.$refs.form.resetValidation();
						this.error = 'same_password';
						break;
					case 423:
						this.$refs.form.resetValidation();
						this.error = 'password_change_lockout';
						break;
					default:
						break;
				}
			}
		},
		setStep(step) {
			this.step = step;
			this.$gtag.pageview(`${this.$route.path}?step=${step}`);
		}
	}
};
</script>
