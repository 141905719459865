import Vue from 'vue';

import i18n from '@/plugins/i18n';
import App from '@/pages/App.vue';
import router from '@/pages/router';
import vuetify from '@/plugins/vuetify';
import store from '@/store';

Vue.config.productionTip = false;

new Vue({
	router,
	vuetify,
	i18n,
	render: (h) => h(App),
	store
}).$mount('#app');
