<template>
	<v-container>
		<v-dialog v-model="dialog.delete" persistent max-width="600px">
			<v-card>
				<v-card-title> {{ $t('recovery_email.dialog.title_delete_confirm') }} </v-card-title>
				<v-card-text>
					{{ $t('recovery_email.dialog.text_delete_confirm', { email: recoveryEmail.delete }) }}
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="secondary" @click="dialog.delete = false" min-width="100px">
						{{ $t('recovery_email.dialog.disagree_label') }}
					</v-btn>
					<v-btn color="error" @click="deleteRecoveryEmail" min-width="100px">
						{{ $t('recovery_email.dialog.agree_label_delete') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialog.change" persistent max-width="600px">
			<v-card>
				<v-card-title> {{ $t('recovery_email.dialog.title_change_confirm') }} </v-card-title>
				<v-card-text>
					{{
						$t('recovery_email.dialog.text_change_confirm', {
							email: recoveryEmail.changeToPrimary
						})
					}}
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="secondary" @click="dialog.change = false" min-width="100px">
						{{ $t('recovery_email.dialog.disagree_label') }}
					</v-btn>
					<v-btn color="primary" @click="changeToPrimary" min-width="100px">
						{{ $t('recovery_email.dialog.agree_label_change') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-row>
			<v-col cols="12" ms="8" md="8" lg="8" xl="8" class="pa-1 pa-sm-3 pr-sm-1">
				<v-card elevation="1" class="ma-sm-3 mr-sm-0">
					<v-form v-model="valid" ref="form">
						<v-card-title> {{ $t('recovery_email.title') }} </v-card-title>
						<v-card-subtitle>
							<v-row class="px-sm-4">
								<v-col cols="10">
									<span v-if="recoveryEmails.length === 1">
										{{ $t('recovery_email.msg.no_recovery_email') }}
									</span>
									<span v-if="recoveryEmails.length >= 3">
										{{ $t('recovery_email.msg.reach_upper_limit') }}</span
									>
								</v-col>
								<v-col cols="2" class="text-right">
									<span>{{ counter }} / 2</span>
								</v-col>
							</v-row>
						</v-card-subtitle>
						<v-card-text>
							<v-alert
								border="left"
								type="error"
								dense
								dismissible
								close-label="alert-list"
								class="body-2"
								:value="!!errorLabel.list"
								@input="errorLabel.list = null"
							>
								<span v-if="errorLabel.list"> {{ $t(`errors.${errorLabel.list}`) }} </span>
							</v-alert>
							<v-list>
								<v-list-item
									class="pl-0 pr-0"
									v-for="recoveryEmail in recoveryEmails"
									:key="recoveryEmail.email"
								>
									<v-list-item-content>
										<v-row align="center">
											<v-col cols="2" sm="4" md="4" lg="4" xl="4">
												<v-chip
													v-if="recoveryEmail.isPrimary"
													small
													class="ma-2 px-1 d-inline-block text-center chip"
													color="#D70027"
													text-color="white"
												>
													<span class="d-sm-none">
														<v-icon> mdi-account-check </v-icon>
													</span>
													<span class="d-none d-sm-block">
														{{ $t('recovery_email.login_id') }}
													</span>
												</v-chip>
												<v-chip
													v-else
													small
													class="ma-2 px-1 d-inline-block text-center chip"
													@click="confirmChangeToPrimary(recoveryEmail.email)"
												>
													<span class="d-sm-none">
														<v-icon> mdi-cached </v-icon>
													</span>
													<span class="d-none d-sm-block">
														{{ $t('recovery_email.change_login_id') }}
													</span>
												</v-chip>
											</v-col>
											<v-col cols="10" sm="8" md="8" lg="8" xl="8">
												<v-list-item-title>{{ recoveryEmail.email }}</v-list-item-title>
											</v-col>
										</v-row>
									</v-list-item-content>
									<v-list-item-icon>
										<v-btn
											v-if="!recoveryEmail.isPrimary"
											@click.stop="confirmDelete(recoveryEmail.email)"
											icon
										>
											<v-icon> mdi-delete </v-icon>
										</v-btn>
									</v-list-item-icon>
								</v-list-item>
							</v-list>
						</v-card-text>
					</v-form>
				</v-card>

				<v-scroll-y-transition>
					<v-card v-show="enabled" elevation="1" class="mt-1 ma-sm-3 mr-sm-0">
						<v-stepper v-model="step" alt-labels>
							<v-stepper-items>
								<v-stepper-content step="1" class="pa-0">
									<v-form v-model="valid" ref="form">
										<v-card-title> {{ $t('recovery_email.add_title') }} </v-card-title>
										<v-card-text>
											<v-alert
												border="left"
												type="error"
												dense
												dismissible
												close-label="alert-register"
												class="body-2"
												:value="!!errorLabel.register"
												@input="errorLabel.register = null"
											>
												<span v-if="errorLabel.register">
													{{ $t(`errors.${errorLabel.register}`) }}
												</span>
											</v-alert>

											<EmailInput
												v-model="recoveryEmail.register"
												:label="$t('recovery_email.label')"
												@blur="$refs.form.validate()"
												class="pl-4 pr-4"
											/>
										</v-card-text>
										<v-card-actions>
											<v-spacer />
											<v-btn color="primary" @click="add" :disabled="!valid" min-width="100px">
												{{ $t('recovery_email.add') }}
											</v-btn>
										</v-card-actions>
									</v-form>
								</v-stepper-content>
								<v-stepper-content step="2" class="pa-0">
									<v-card-title> {{ $t('recovery_email.add_title') }} </v-card-title>
									<v-card-text>
										{{
											$t('recovery_email.msg.add_recovery_email', { email: recoveryEmail.register })
										}}
									</v-card-text>
									<v-card-actions>
										<v-spacer />
										<v-btn color="primary" @click="setStep(1)" min-width="100px">
											{{ $t('recovery_email.continue_add') }}
										</v-btn>
									</v-card-actions>
								</v-stepper-content>
							</v-stepper-items>
						</v-stepper>
					</v-card>
				</v-scroll-y-transition>
			</v-col>

			<v-col cols="12" md="4" lg="4" xl="4" class="pa-1 pa-sm-3 pl-sm-1">
				<div class="ma-sm-3 ml-sm-0">
					<v-expansion-panels accordion outlined elevation="0" flat class="faq">
						<h3>{{ $t('recovery_email.faq.title') }}</h3>
						<v-expansion-panel v-for="faq in faqs" :key="faq" class="grey lighten-5">
							<div style="border-bottom: 1px solid #ccc">
								<v-expansion-panel-header>
									{{ $t(`recovery_email.faq.headers.${faq}`) }}
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									{{ $t(`recovery_email.faq.items.${faq}`) }}
								</v-expansion-panel-content>
							</div>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import EmailInput from '@/components/Input/EmailInput.vue';

export default {
	name: 'RecoveryEmail',
	components: {
		EmailInput
	},
	data: () => ({
		recoveryEmails: [],
		recoveryEmail: {
			register: '',
			delete: '',
			changeToPrimary: ''
		},
		step: 1,
		valid: true,
		dialog: {
			change: false,
			delete: false
		},
		errorLabel: {
			list: null,
			register: null
		},
		faqs: ['what_login_id', 'what_recovery_email']
	}),
	computed: {
		counter() {
			return !!this.recoveryEmails.length - 1 ? 0 : this.recoveryEmails.length - 1;
		},
		enabled() {
			return this.recoveryEmails.length === 0 ? false : this.recoveryEmails.length < 3;
		}
	},
	async created() {
		await this.setRecovryEmail();
	},
	methods: {
		async setRecovryEmail() {
			try {
				const {
					data: { recoveryEmails }
				} = await this.$axios.oidc.get(`/api/v1/user/recovery/emails`);
				this.recoveryEmails = recoveryEmails;
			} catch (e) {
				this.errorLabel.list = 'unknown';
			}
		},
		async add() {
			this.resetError();

			await this.$refs.form.validate();
			if (!this.valid) return;

			try {
				await this.$axios.oidc.post(`/api/v1/user/recovery/email`, {
					email: this.recoveryEmail.register,
					sendmail: true
				});
				this.setStep(2);
			} catch (e) {
				this.errorLabel.register = 'unknown';
				switch (e.response?.status) {
					case 403:
						this.errorLabel.register = 'recovery_email_exceed';
						break;
					case 409:
						this.errorLabel.register = 'existing_email';
						break;
					default:
				}
			}
		},
		async deleteRecoveryEmail() {
			this.resetError();
			this.dialog.delete = false;
			try {
				await this.$axios.oidc.delete(`/api/v1/user/recovery/email/${this.recoveryEmail.delete}`);
				await this.setRecovryEmail();
			} catch (e) {
				this.errorLabel.list = 'unknown';
				switch (e.response?.status) {
					case 403:
						this.errorLabel.list = 'primary_protected';
						break;
					case 404:
						this.errorLabel.list = 'recovery_email_not_found';
						break;
					default:
				}
			}
		},
		async changeToPrimary() {
			this.resetError();
			this.dialog.change = false;
			try {
				await this.$axios.oidc.put(
					`/api/v1/user/recovery/email/${this.recoveryEmail.changeToPrimary}`,
					{
						isPrimary: true
					}
				);
				await this.setRecovryEmail();
			} catch (e) {
				this.errorLabel.list = 'unknown';
				switch (e.response?.status) {
					case 403:
						this.errorLabel.list = 'registered_primary';
						break;
					default:
				}
			}
		},
		resetError() {
			this.errorLabel = { list: null, register: null };
		},
		confirmDelete(email) {
			this.resetError();
			this.dialog.delete = true;
			this.recoveryEmail.delete = email;
		},
		confirmChangeToPrimary(email) {
			this.resetError();
			this.dialog.change = true;
			this.recoveryEmail.changeToPrimary = email;
		},
		setStep(step) {
			this.step = step;
			if (step === 1) this.$refs.form.reset();

			this.$gtag.pageview(`${this.$route.path}?step=${step}`);
		}
	}
};
</script>

<style lang="sass" scoped>
.v-chip
	&:hover
		cursor: pointer
.faq
	justify-content: left !important
	h3
		font-size: 1rem
		color: #666
		font-weight: 500
	.v-expansion-panel-header
		padding: 16px 0
		color: #666
		font-size: 0.8rem
		font-weight: 500
	.v-expansion-panel-content
		&::v-deep
			.v-expansion-panel-content__wrap
				padding: 0 0 16px 0 !important
		color: #999
		font-size: 0.8rem
.chip
	@media screen and (min-width: 600px)
		min-width: 125px
</style>
