<template>
	<div>
		<v-stepper-content step="2" class="pa-0">
			<v-card-subtitle>
				{{ $t('multi_factor_auth.mail.send_mail') }}
			</v-card-subtitle>
			<v-card-actions class="justify-end">
				<v-btn color="secondary" min-width="100px" @click="$emit('setStep', 1)">
					{{ $t('multi_factor_auth.prev') }}
				</v-btn>
				<v-btn color="primary" min-width="100px" @click="sendMail">
					{{ $t('multi_factor_auth.send_mail') }}
				</v-btn>
			</v-card-actions>
		</v-stepper-content>
		<v-stepper-content step="3" class="pa-0">
			<v-card-title> {{ $t('multi_factor_auth.activate.title') }} </v-card-title>
			<v-card-subtitle>
				{{ $t('multi_factor_auth.mail.enter_code') }}
			</v-card-subtitle>
			<v-card-text>
				<v-alert
					border="left"
					type="error"
					dense
					dismissible
					class="body-2"
					:value="!!errorLabel"
					@input="errorLabel = null"
				>
					{{ $t(`errors.${errorLabel}`) }}
				</v-alert>
				<v-row>
					<CodeInput v-model="token" />
				</v-row>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn color="secondary" min-width="100px" @click="resetCode(2)" :disabled="failOver">
					{{ $t('multi_factor_auth.prev') }}
				</v-btn>
				<v-btn
					v-if="!failOver"
					color="primary"
					min-width="100px"
					@click="verify"
					:disabled="!token || token.length !== 6"
				>
					{{ $t('multi_factor_auth.next') }}
				</v-btn>
				<v-btn v-else color="primary" min-width="100px" @click="resetCode(1)">
					{{ $t('multi_factor_auth.to_first_step') }}
				</v-btn>
			</v-card-actions>
		</v-stepper-content>
		<v-stepper-content step="4" class="pa-0 pb-10">
			<v-card-title>{{ $t('multi_factor_auth.activate.title') }}</v-card-title>
			<v-card-text> {{ $t('multi_factor_auth.activate.step5.message') }} </v-card-text>
		</v-stepper-content>
	</div>
</template>

<script>
import CodeInput from '@/components/Input/CodeInput.vue';

export default {
	name: 'MailStep',
	components: {
		CodeInput
	},
	props: {
		step: {
			type: Number,
			default: 0,
			required: true
		}
	},
	data: () => ({
		token: '',
		loading: false,
		failCount: 0,
		errorLabel: null
	}),
	computed: {
		failOver() {
			return this.failCount >= 3;
		}
	},
	watch: {
		token(_, oldValue) {
			if (this.token.length !== 6 || oldValue.length === 6) return;
			this.verify();
		}
	},
	methods: {
		async verify() {
			try {
				this.loading = true;
				await this.$axios.oidc.post(`/api/v1/user/mfa/verify`, {
					token: this.token
				});
				this.$emit('completed');
				this.$emit('setStep', 4);
			} catch (e) {
				this.errorLabel = 'unknown';
				switch (e.response?.status) {
					case 400:
						this.failCount += 1;
						this.errorLabel = 'verification_code';
						if (this.failOver) this.errorLabel = 'verification_code_fail';
						break;
					default:
				}
			} finally {
				this.loading = false;
			}
		},
		async sendMail() {
			try {
				this.loading = true;
				await this.$axios.oidc.post(`/api/v1/user/mfa`, { appEnabled: false });
				await this.$axios.oidc.post(`/api/v1/user/mfa/token/sendmail`);
				this.$emit('setStep', 3);
			} catch (e) {
				this.errorLabel = 'unknown';
			} finally {
				this.loading = false;
			}
		},
		resetCode(step) {
			this.$emit('setStep', step);
			this.token = '';
			this.failCount = 0;
			this.errorLabel = '';
		}
	}
};
</script>
