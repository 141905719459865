<template>
	<div>
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card>
				<v-card-title>
					{{ $t('account_info.dialog.title_transition_confirm', { websiteName: label }) }}
				</v-card-title>
				<v-card-text>
					{{ $t('account_info.dialog.transition_confirm', { url: value }) }}
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn color="secondary" @click="dialog = false" min-width="100px">
						{{ $t('account_info.dialog.disagree_label') }}
					</v-btn>
					<v-btn color="primary" @click="openSite" min-width="100px">
						{{ $t('account_info.dialog.agree_label_transition') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-container>
			<v-row justify="start">
				<v-text-field
					:value="value"
					@input="onInput"
					:rules="rules"
					:label="label"
					:required="required"
					:prepend-icon="prependIcon"
					:validate-on-blur="validateOnBlur"
					:counter="readonly ? null : maxlen"
					:clearable="clearable"
					:tabindex="tabindex"
					:readonly="readonly"
					:disabled="disabled"
				/>
				<div class="pt-4">
					<v-btn icon @click="onClick">
						<v-icon>mdi-open-in-new</v-icon>
					</v-btn>
				</div>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import validUrl from 'valid-url';

export default {
	props: {
		value: {
			type: String,
			required: false,
			default: ''
		},
		validateOnBlur: {
			type: Boolean,
			required: false,
			default: false
		},
		label: {
			type: String,
			required: false,
			default: 'Link'
		},
		required: {
			type: Boolean,
			required: false,
			default: false
		},
		clearable: {
			type: Boolean,
			required: false,
			default: false
		},
		tabindex: {
			type: Number,
			required: false,
			default: 0
		},
		prependIcon: {
			type: String,
			required: false,
			default: 'mdi-lock'
		},
		minlen: {
			type: Number,
			required: false,
			default: 8
		},
		maxlen: {
			type: Number,
			required: false,
			default: 32
		},
		readonly: {
			type: Boolean,
			required: false,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		disableRequiredValidation: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => ({
		dialog: false
	}),
	computed: {
		rules() {
			return [
				(v) =>
					this.disableRequiredValidation || !!v || this.$t('errors.required', { key: this.label }),
				// 以下０場合にvalidationが通ってしまうので要注意
				(v) =>
					!v || !!validUrl.isWebUri(v) || this.$t('errors.http_format_check', { key: this.label }),
				(v) =>
					!v ||
					v.length >= this.minlen ||
					this.$t('errors.minlen', { key: this.label, minlen: this.minlen }),
				(v) =>
					!v ||
					v.length <= this.maxlen ||
					this.$t('errors.maxlen', { key: this.label, maxlen: this.maxlen })
			];
		}
	},
	methods: {
		onInput(value) {
			this.$emit(`input`, value);
		},
		onClick() {
			this.dialog = true;
		},
		openSite() {
			window.open(this.value, '_blank');
			this.dialog = false;
		}
	}
};
</script>
