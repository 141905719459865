export default () => {
	const { port, protocol, hostname } = window.location;

	if (port) return 'env-local';
	if (protocol === 'https:') {
		if (hostname.startsWith('dev.') || hostname.indexOf('.dev.') !== -1) return 'env-dev';
		if (hostname.startsWith('sandbox.') || hostname.indexOf('.sandbox.') !== -1)
			return 'env-sandbox';
		if (hostname.startsWith('green.')) return 'env-green';
	}
	return 'env-production';
};
