<template>
	<v-app>
		<NavigationHeader disabled-menu disabled-reader />
		<v-main class="grey lighten-5">
			<v-container>
				<v-row class="justify-center">
					<v-col cols="12" ms="8" md="8" lg="8" xl="8" class="pa-1 pa-sm-3 pr-sm-1">
						<v-card elevation="1" class="ma-sm-3 mr-sm-0">
							<v-card-title> {{ $t('not_login.title') }} </v-card-title>
							<v-card-text>
								{{ $t('not_login.text') }}
							</v-card-text>
							<v-card-actions>
								<v-spacer />
								<v-btn color="primary" @click="login" min-width="100px">
									{{ $t('not_login.login') }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import NavigationHeader from '@/components/NavigationHeader.vue';

export default {
	name: 'NotLogined',
	components: {
		NavigationHeader
	},
	methods: {
		login() {
			window.location.href = '/login/?type=new';
		}
	}
};
</script>
