<template>
	<div>
		<v-select
			:value="genderValue.gender"
			:items="gendersItems"
			:label="$t('account_info.gender')"
			:clearable="clearable"
			:readonly="readonly"
			:disabled="disabled"
			:required="required"
			:rules="genderRules"
			:item-text="selectedGenderValue"
			@change="selected"
			prepend-icon="mdi-gender-male-female"
		/>
		<v-combobox
			v-if="genderValue.gender === 'custom'"
			:value="genderValue.genderCustomText"
			:items="customGendersItems"
			:counter="readonly ? null : genderCustomTextMaxLength"
			:label="$t('account_info.gender_custom_info')"
			:clearable="clearable"
			:readonly="readonly"
			:disabled="disabled"
			:rules="genderCustomTextRules"
			@change="changed"
			prepend-icon="mdi-gender-transgender"
			:disableRequiredValidation="disableRequiredValidation"
		/>
	</div>
</template>
<script>
import { GENDERS, CUSTOM_GENDERS } from '@/constants/genderInfo';

export default {
	model: {
		prop: `genderValue`
	},
	props: {
		genderValue: {
			type: Object,
			required: false,
			default: () => ({
				gender: 'custom',
				genderCustomText: null
			})
		},
		required: {
			type: Boolean,
			required: false,
			default: true
		},
		clearable: {
			type: Boolean,
			required: false,
			default: true
		},
		readonly: {
			type: Boolean,
			required: false,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		disableRequiredValidation: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => ({
		genderCustomTextMaxLength: 36,
		minlen: 2,
		gendersItems: GENDERS,
		customGendersItems: CUSTOM_GENDERS
	}),
	computed: {
		genderRules() {
			return [(v) => !!v || this.$t('errors.required', { key: this.$t('account_info.gender') })];
		},
		genderCustomTextRules() {
			return [
				(v) =>
					this.disableRequiredValidation ||
					!!v ||
					this.$t('errors.required', { key: this.$t('account_info.gender') }),
				// 以下０場合にvalidationが通ってしまうので要注意
				(v) =>
					!v ||
					v.length >= this.minlen ||
					this.$t('errors.minlen', { key: this.$t('account_info.gender'), minlen: this.minlen }),
				(v) =>
					!v ||
					v.length <= this.genderCustomTextMaxLength ||
					this.$t('errors.maxlen', {
						key: this.$t('account_info.gender'),
						maxlen: this.genderCustomTextMaxLength
					})
			];
		},
		selectedGenderValue() {
			return (item) => this.$t(`account_info.${item.value}`);
		}
	},
	methods: {
		changed(value) {
			this.$emit(`input`, {
				gender: this.genderValue.gender,
				genderCustomText: this.genderValue.gender !== 'custom' ? null : value
			});
		},
		selected(value) {
			this.$emit(`input`, {
				gender: value,
				genderCustomText: value !== 'custom' ? null : this.genderValue.genderCustomText
			});
		}
	}
};
</script>
